import React from 'react'
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Button, Text } from 'react-native-elements'

const Banner = (props) => {
  return (
    <TouchableOpacity
      style={{ ...styles.bannerContainer }}
      key={props.title}
      onPress={props.onPress}>
      <LinearGradient
        // Background Linear Gradient
        colors={['transparent', 'rgba(0,0,0,0.8)']}
        style={{ ...styles.banner }}
        start={{ x: 0, y: 0 }}
        end={{ x: 0.7, y: 1 }}>
        <View style={{ ...styles.bannerTxt }}>
          <Text
            style={{
              color: 'white',
              fontSize: props.title.length > 30 ? 20 : 24,
              fontWeight: 'bold',
            }}
          >
            {props.title}
          </Text>
          <Text style={{ color: 'white', fontSize: 14 }}>{props.subtitle}</Text>
        </View>
        {props.img ? (
          <View style={{ ...styles.imgContainer }}>
            <Image
              source={{ uri: `${global.BACKEND_HOST}${props.img}` }}
              style={{ ...styles.bannerImg }}
              resizeMode="cover"
              transition={false}
              placeholderStyle={{ backgroundColor: 'white' }}
              containerStyle={{ resizeMode: 'cover' }}
            />
          </View>
        ) : null}
      </LinearGradient>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  bannerContainer: {
    marginVertical: 10,
    marginHorizontal: 10,
  },
  banner: {
    flexDirection: 'row',
    backgroundColor: '#7962E0',
    borderRadius: 25,
    width: '100%',
    height: 140,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    overflow: 'hidden',
  },
  bannerTxt: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '60%',
    paddingRight: 10,
  },
  imgContainer: {
    width: '40%',
    height: '100%',
  },
  bannerImg: {
    height: '100%',
    resizeMode: 'cover',
    flexGrow: 1,
  },
})

export default Banner
