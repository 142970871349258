import React, { Component } from 'react'
import { View, Dimensions, StyleSheet, Alert, Platform } from 'react-native'
import { Button, Image, Text, ButtonGroup } from 'react-native-elements'
import DateTimePicker from '@react-native-community/datetimepicker'
import CustomText from '../../components/ui_elements/custom_text'
import RoundedButton from '../../components/ui_elements/rounded_button'
import B from '../../components/ui_elements/text_styling'
import Question from './Question'
import _ from 'lodash'
import { Modal } from 'react-native-web'
import moment from 'moment'
import 'moment/locale/es'
import Icon from 'react-native-vector-icons/FontAwesome5'
import { checkWidth } from '../../constants'
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler'
import WebDatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'

export default class ProfileSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: -1,
      birth_date: null,
      sex: null,
      ocupation: null,
      education_level: null,
      showDTPicker: Platform.OS === 'ios',
    }
    this.questionsOrder = ['birth_date', 'sex', 'ocupation', 'education_level']
  }

  componentDidMount() {
    this.setState({ ...this.props.auth.user })
    this.props.actions.getProfileOptions(this.props.auth.access_token)
  }

  fieldsData = (fieldName) => {
    switch (fieldName) {
      case 'birth_date':
        return {
          fieldType: 'date',
          title: '¿Cuál es tu fecha de nacimiento?',
          description: '',
          value: this.state.birth_date,
        }
      case 'sex':
        return {
          fieldType: 'value',
          title: '¿Cuál es tu sexo?',
          description: '',
          value: this.state.sex,
        }
      case 'ocupation':
        return {
          fieldType: 'value',
          title: '¿Cuál es tu ocupación?',
          description: '',
          value: this.state.ocupation,
        }
      case 'education_level':
        return {
          fieldType: 'value',
          title: '¿Cuál es tu nivel de educación?',
          description: '',
          value: this.state.education_level,
        }
    }
  }

  posibleValues = (fieldName) => {
    switch (fieldName) {
      case 'sex':
        return _.keys(this.props.auth?.profile_options?.sexes)
      case 'ocupation':
        return _.keys(this.props.auth?.profile_options?.ocupations)
      case 'education_level':
        return _.keys(this.props.auth?.profile_options?.education_levels)
    }
  }

  setFieldValue = (fieldName, newVal) => {
    switch (fieldName) {
      case 'birth_date':
        this.setState({
          birth_date: newVal,
          showDTPicker: Platform.OS === 'ios' ? true : false,
        })
      case 'sex':
        this.setState({ sex: newVal })
      case 'ocupation':
        this.setState({ ocupation: newVal })
      case 'education_level':
        this.setState({ education_level: newVal })
    }
  }

  quitQuestions = () => {
    this.props.actions.updateProfile(this.props.auth.access_token, {
      should_complete_profile: false,
    })
    this.props.actions.setCurrentNavigator('main')
  }

  _handleNextBtn = () => {
    if (0 <= this.state.step) {
      var userData = {}
      userData[this.questionsOrder[this.state.step]] = this.fieldsData(
        this.questionsOrder[this.state.step]
      ).value
      this.props.actions.updateProfile(this.props.auth.access_token, userData)
      if (this.state.step == this.questionsOrder.length - 1) {
        this.quitQuestions()
      }
    }
    if (this.state.step < this.questionsOrder.length - 1) {
      this.setState((prevState) => ({ step: prevState.step + 1 }))
    }
  }

  _renderBackBtn = () => {
    if (-1 < this.state.step) {
      return (
        <RoundedButton
          style={{ marginTop: 0, backgroundColor: 'white' }}
          containerStyle={{ padding: 5, zIndex: -1 }}
          textStyling={{ color: '#7962E0' }}
          onPress={() =>
            this.setState((prevState) => ({ step: prevState.step - 1 }))
          }
        >
          Volver
        </RoundedButton>
      )
    }
  }
  _renderNextBtn = () => {
    if (this.state.step <= this.questionsOrder.length) {
      return (
        <RoundedButton
          style={{ marginTop: 30 }}
          containerStyle={{ padding: 5 }}
          onPress={() => this._handleNextBtn()}
        >
          {this.state.step < this.questionsOrder.length - 1
            ? 'Siguiente'
            : 'Terminar'}
        </RoundedButton>
      )
    }
  }

  _renderSkipBtn = () => {
    return (
      <TouchableOpacity
        style={{ marginTop: 10 }}
        onPress={() =>
          this.props.actions.updateProfile(this.props.auth?.access_token, {
            should_complete_profile: false,
          })
        }
      >
        <Text style={{ fontSize: 16, color: '#666', textAlign: 'center' }}>
          {'Continuar más tarde'}
        </Text>
      </TouchableOpacity>
    )
  }

  _renderStep = () => {
    // titles = ["Completa tu perfil", "¿Cuál es tu fecha de nacimiento?", "¿Cuál es tu sexo?", "¿Cuál es tu ocupación?", "¿Cuál es tu nivel de educación?"]
    // descs = ["Mientras más completo sea tu perfil\n¡Podremos mostrarte mejores beneficios!", "", "", "", ""]
    const btns = [
      [],
      _.keys(this.props.auth?.profile_options?.sexes),
      _.keys(this.props.auth?.profile_options?.ocupations),
      _.keys(this.props.auth?.profile_options?.education_levels),
    ]

    if (this.state.step == -1) {
      return (
        <View>
          <View style={{ alignItems: 'center' }}>
            <CustomText title center>
              {'Completa tu perfil'}
            </CustomText>
            <CustomText center>
              {
                'Mientras más completo sea tu perfil\n¡Podremos mostrarte mejores beneficios!'
              }
            </CustomText>
          </View>
          <View>
            {this._renderNextBtn()}
            {this._renderBackBtn()}
            <View>{this._renderSkipBtn()}</View>
          </View>
        </View>
      )
    } else if (
      this.fieldsData(this.questionsOrder[this.state.step]).fieldType == 'value'
    ) {
      return (
        <View>
          <View style={{ alignItems: 'center' }}>
            <CustomText title center>
              {this.fieldsData(this.questionsOrder[this.state.step]).title}
            </CustomText>
            <CustomText center>
              {
                this.fieldsData(this.questionsOrder[this.state.step])
                  .description
              }
            </CustomText>

            <ButtonGroup
              buttons={this.posibleValues(this.questionsOrder[this.state.step])}
              vertical={true}
              selectedIndex={
                this.fieldsData(this.questionsOrder[this.state.step]).value
              }
              buttonStyle={{
                width: Platform.OS === 'web' ? (checkWidth ? 300 : 500) : 300,
              }}
              // buttonContainerStyle={{backgroundColor: 'red'}}
              containerStyle={
                Platform.OS === 'web'
                  ? checkWidth
                    ? {
                        maxHeight:
                          this.posibleValues(
                            this.questionsOrder[this.state.step]
                          ).length * 40,
                      }
                    : {
                        height:
                          this.posibleValues(
                            this.questionsOrder[this.state.step]
                          ).length * 40,
                      }
                  : {
                      maxHeight:
                        this.posibleValues(this.questionsOrder[this.state.step])
                          .length * 40,
                    }
              }
              selectedButtonStyle={{ backgroundColor: '#7962E0' }}
              // selectedIndex={sex}
              onPress={(val) => {
                this.setFieldValue(this.questionsOrder[this.state.step], val)
              }}
            />
          </View>
          <View>
            {this._renderNextBtn()}
            {this._renderBackBtn()}
          </View>
          <View>{this._renderSkipBtn()}</View>
        </View>
      )
    } else if (
      this.fieldsData(this.questionsOrder[this.state.step]).fieldType == 'date'
    ) {
      return (
        <View>
          <View style={{ alignItems: 'center' }}>
            <CustomText title center>
              {this.fieldsData(this.questionsOrder[this.state.step]).title}
            </CustomText>
            <CustomText center>
              {
                this.fieldsData(this.questionsOrder[this.state.step])
                  .description
              }
            </CustomText>
            {this.state.birth_date != null && (
              <CustomText center>
                <B>
                  {moment(this.state.birth_date).format('D [de] MMMM [de] Y')}
                </B>
              </CustomText>
            )}
            {Platform.OS != 'ios' && (
              <RoundedButton
                style={{
                  marginTop: 50,
                  marginBottom: 20,
                  backgroundColor: '#E2501E',
                }}
                onPress={() => this.setState({ showDTPicker: true })}
              >
                Seleccionar
              </RoundedButton>
            )}
            {this.state.showDTPicker && Platform.OS === 'web' ? (
              <WebDatePicker
                withPortal
                dropdownMode="scroll"
                style={{ width: '100%', marginLeft: '10%' }}
                selected={
                  new Date(
                    this.fieldsData(this.questionsOrder[this.state.step]).value
                  )
                }
                onChange={(val) => {
                  this.setFieldValue(this.questionsOrder[this.state.step], val)
                }}
              />
            ) : (
              <DateTimePicker
                style={{ width: '100%' }}
                value={
                  new Date(
                    this.fieldsData(this.questionsOrder[this.state.step]).value
                  )
                }
                mode={'date'}
                display={Platform.OS === 'ios' ? 'spinner' : 'default'}
                onChange={(event, val) => {
                  event?.type === 'dismissed'
                    ? null
                    : this.setFieldValue(
                        this.questionsOrder[this.state.step],
                        val
                      )
                }}
              />
            )}
          </View>
          <View style={{ position: 'relative', zIndex: -1 }}>
            {this._renderNextBtn()}
            {this._renderBackBtn()}
            {this._renderSkipBtn()}
          </View>
        </View>
      )
    } else if (
      this.fieldsData(this.questionsOrder[this.state.step]).fieldType ==
      'location'
    ) {
      return (
        <View>
          <View style={{ alignItems: 'center' }}>
            <CustomText title center>
              {this.fieldsData(this.questionsOrder[this.state.step]).title}
            </CustomText>
            <CustomText center>
              {
                this.fieldsData(this.questionsOrder[this.state.step])
                  .description
              }
            </CustomText>
          </View>
          <View>
            {this._renderNextBtn()}
            {this._renderBackBtn()}
          </View>
          <View>{this._renderSkipBtn()}</View>
        </View>
      )
    } else {
      return (
        <View>
          <View>
            {this._renderNextBtn()}
            {this._renderBackBtn()}
          </View>
          <View>{this._renderSkipBtn()}</View>
        </View>
      )
    }
  }

  render() {
    const should_complete_profile =
      this?.props?.auth?.user?.should_complete_profile
    moment.locale('es')
    {
      return should_complete_profile !== true ? (
        <Question
          user={this.props.auth?.user}
          onSignout={() => {
            this.props.actions.signout()
          }}
          navigation={this.props.navigation}
          onDeleteAccount={() => {
            Alert.alert(
              'Esta acción es irreversible',
              'Estas seguro de que quieres eliminar tu cuenta?',
              [
                {
                  text: 'Cancelar',
                },
                {
                  text: 'Eliminar cuenta',
                  onPress: () => {
                    this.props.actions.updateProfile(
                      this.props.auth.access_token,
                      { delete_account: true }
                    )
                  },
                },
              ]
            )
            // this.props.actions.updateProfile(this.props.auth.access_token, { delete_account: true })
          }}
        />
      ) : (
        <ScrollView style={styles.mainContainer}>
          {/* <Text>{this.props.auth?.user?.should_complete_profile}</Text> */}
          {/* <Text>{JSON.stringify(this.props.auth?.user)}</Text> */}
          <View style={{ alignItems: 'center' }}>
            {/* <Text>{JSON.stringify(this.props.auth?.user)}</Text> */}
            {this._renderStep()}
          </View>
        </ScrollView>
      )
    }
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingTop: 20,
    height: Dimensions.get('window').height,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
  },
})
