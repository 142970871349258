import * as types from '../actionTypes/posts'
import * as authTypes from '../actionTypes/auth'
import { fetchWithTimeout } from '../components/fetchWithTimeout'
import * as qs from 'qs'

export function setStatusSuccess() {
  return async (dispatch) => {
    dispatch({ type: types.SET_STATUS_SUCCESS })
  }
}

export function getPosts(token) {
  return async (dispatch) => {
    dispatch({ type: types.GET_POSTS })
    try {
      console.log('now is fetching posts')
      let response = await fetchWithTimeout(`${global.API_URL}/posts`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
      console.log(response.status)
      if (response.status === 200) {
        const resp = await response.json()
        dispatch({ type: types.GET_POSTS_SUCCESS, data: resp })
      } else if (response.status === 403) {
        dispatch({
          type: types.GET_POSTS_ERROR,
          data: { errors: { Forbidden: ["You don't have permissions"] } },
        })
        dispatch({ type: types.SIGNOUT })
        dispatch({ type: types.SIGNOUT_SUCCESS })
      } else {
        dispatch({
          type: types.GET_POSTS_ERROR,
          data: {
            errors: {
              'Connection error': [`Response code ${response.status}`],
            },
          },
        })
        dispatch({ type: authTypes.SIGNOUT })
        dispatch({ type: authTypes.SIGNOUT_SUCCESS })
      }
    } catch (error) {
      dispatch({
        type: types.GET_POSTS_ERROR,
        data: { errors: { 'Connection error': [`Unknown`] } },
      })
    }
  }
}

export function getFeaturedPosts(token) {
  const obj = { q: { featured_position_not_null: true } }
  return async (dispatch) => {
    dispatch({ type: types.GET_FEATURED_POSTS })
    try {
      let response = await fetchWithTimeout(
        `${global.API_URL}/posts?${qs.stringify(obj)}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      )
      if (response.status === 200) {
        const resp = await response.json()
        dispatch({ type: types.GET_FEATURED_POSTS_SUCCESS, data: resp })
      } else if (response.status === 403) {
        dispatch({
          type: types.GET_FEATURED_POSTS_ERROR,
          data: { errors: { Forbidden: ["You don't have permissions"] } },
        })
        dispatch({ type: types.SIGNOUT })
        dispatch({ type: types.SIGNOUT_SUCCESS })
      } else {
        dispatch({
          type: types.GET_FEATURED_POSTS_ERROR,
          data: {
            errors: {
              'Connection error': [`Response code ${response.status}`],
            },
          },
        })
        dispatch({ type: authTypes.SIGNOUT })
        dispatch({ type: authTypes.SIGNOUT_SUCCESS })
      }
    } catch (error) {
      dispatch({
        type: types.GET_FEATURED_POSTS_ERROR,
        data: { errors: { 'Connection error': [`Unknown`] } },
      })
    }
  }
}

export function getFavorites(token) {
  return async (dispatch) => {
    dispatch({ type: types.GET_FAVORITE_POSTS })
    try {
      let response = await fetchWithTimeout(
        `${global.API_URL}/posts/favorites`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      )
      if (response.status === 200) {
        const resp = await response.json()
        dispatch({ type: types.GET_FAVORITE_POSTS_SUCCESS, data: resp })
      } else if (response.status === 403) {
        dispatch({
          type: types.GET_FAVORITE_POSTS_ERROR,
          data: { errors: { Forbidden: ["You don't have permissions"] } },
        })
        dispatch({ type: types.SIGNOUT })
        dispatch({ type: types.SIGNOUT_SUCCESS })
      } else {
        dispatch({
          type: types.GET_FAVORITE_POSTS_ERROR,
          data: {
            errors: {
              'Connection error': [`Response code ${response.status}`],
            },
          },
        })
      }
    } catch (error) {
      dispatch({
        type: types.GET_FAVORITE_POSTS_ERROR,
        data: { errors: { 'Connection error': [`Unknown`] } },
      })
    }
  }
}

export function toggleFavorite(token, postID, toggleAction = 'add') {
  return async (dispatch) => {
    dispatch({ type: types.TOGGLE_FAVORITE })
    try {
      let response = await fetchWithTimeout(
        `${global.API_URL}/posts/${postID}/toggle_favorite`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({ toggle_action: toggleAction }),
        }
      )
      if (response.status === 200) {
        const resp = await response.json()
        dispatch({ type: types.TOGGLE_FAVORITE_SUCCESS, data: resp })
      } else if (response.status === 403) {
        dispatch({
          type: types.TOGGLE_FAVORITE_ERROR,
          data: { errors: { Forbidden: ["You don't have permissions"] } },
        })
        dispatch({ type: types.SIGNOUT })
        dispatch({ type: types.SIGNOUT_SUCCESS })
      } else {
        dispatch({
          type: types.TOGGLE_FAVORITE_ERROR,
          data: {
            errors: {
              'Connection error': [`Response code ${response.status}`],
            },
          },
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: types.TOGGLE_FAVORITE_ERROR,
        data: {
          errors: {
            'Connection error': [`Se ha excedido el tiempo de espera máximo`],
          },
        },
      })
    }
  }
}

export async function searchPost(token, searchWord, page = 1) {
  try {
    let response = await fetchWithTimeout(
      `${global.API_URL}/posts/search?condition=${searchWord}&page=${page}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    )
    console.log(response)
    if (response.status === 200) {
      const resp = await response.json()
      return resp
    } else if (response.status === 403) {
      dispatch({ type: types.SIGNOUT })
      dispatch({ type: types.SIGNOUT_SUCCESS })
    }
    return []
  } catch (error) {
    return []
  }
}

export async function recentSearches(token) {
  try {
    let response = await fetchWithTimeout(
      `${global.API_URL}/posts/search_stats`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    )
    if (response.status === 200) {
      const resp = await response.json()
      return resp
    } else if (response.status === 403) {
      dispatch({ type: types.SIGNOUT })
      dispatch({ type: types.SIGNOUT_SUCCESS })
    }
    return []
  } catch (error) {
    return []
  }
}

export async function getCategoriesPost(token, obj) {
  try {
    let response = await fetchWithTimeout(
      `${global.API_URL}/posts?${qs.stringify(obj)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        // body: JSON.stringify(obj),
        // redirect: 'follow',
      }
    )
    if (response.status === 200) {
      const resp = await response.json()
      return resp
    } else if (response.status === 403) {
      dispatch({ type: types.SIGNOUT })
      dispatch({ type: types.SIGNOUT_SUCCESS })
    }
  } catch (error) {
    console.log({ error })
    return error
  }
}
