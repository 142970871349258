import { FlatList, View, TouchableWithoutFeedback } from 'react-native'
import Modal from 'react-native-modal'
import { useSelector } from 'react-redux'
import BottomCategoryCard from '../bottomCategoryCard'

export default function ModalBottomMenu({
  modalVisible,
  setVisibleModal,
  navigate,
}) {
  const categories = useSelector((state) => state.categories.records)

  return (
    <Modal
      style={{ width: '100%', margin: 0 }}
      isVisible={modalVisible}
      onBackdropPress={() => setVisibleModal(false)}
    >
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          height: 250,
          backgroundColor: 'white',
          width: '100%',
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
          padding: 20,
          alignItems: 'center',
        }}
      >
        <View>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={categories}
            numColumns={4}
            keyExtractor={(item, index) => item.id}
            renderItem={({ item, index }) => {
              return (
                <BottomCategoryCard
                  item={item}
                  onPress={() => {
                    setVisibleModal(false)
                    navigate(item)
                  }}
                />
              )
            }}
          />
        </View>
      </View>
    </Modal>
  )
}
