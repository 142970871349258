import React from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import Icon from 'react-native-vector-icons/FontAwesome5'
import { useSelector } from 'react-redux'
// import { Colors } from 'react-native/Libraries/NewAppScreen';
import {
  NavbarButton,
  NavbarActionButton,
  RightBarItems,
} from '../ui_elements/nav/navbar_action_button'
import * as authActions from '../../actions/authActions'
import Favorites from '../../containers/favoritesContainer'

// Admin
import Home from '../../containers/homeContainer'

const FavoritesStack = () => {
  const Stack = createNativeStackNavigator()
  const isAdmin = useSelector((state) => state.auth?.user?.role) === 'admin'
  const isGuest = useSelector((state) => state.auth?.user?.role) === 'guest'

  function LogoTitle() {
    return (
      <View>
        <Image
          style={{
            width: 140,
            height: 50,
            marginLeft: 10,
            resizeMode: 'contain',
          }}
          source={require('../../assets/logo-white.png')}
        />
      </View>
    )
  }

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Favorites"
        component={Favorites}
        options={({ navigation, route }) => ({
          headerLeft: (props) => <LogoTitle {...props} />,
          headerTitle: '',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
    </Stack.Navigator>
  )
}

export default FavoritesStack
