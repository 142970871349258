import React from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
// import { createStackNavigator } from '@react-navigation/stack'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import Landing from '../../screens/login/landing'
import LoginOptions from '../../screens/login/loginOptions'
import Signup from '../../containers/signupContainer'
import RequestLogin from '../../containers/requestLoginContainer'
import LoginConfirm from '../../containers/loginConfirmContainer'
import Icon from 'react-native-vector-icons/FontAwesome5'
import { RightBarItems } from '../ui_elements/nav/navbar_action_button'

const Stack = createNativeStackNavigator()

const AuthStack = () => {
  function LogoTitle() {
    return (
      <View>
        <Image
          style={{
            width: 140,
            height: 50,
            marginLeft: 10,
            resizeMode: 'contain',
          }}
          source={require('../../assets/logo-white.png')}
        />
      </View>
    )
  }

  function renderNavBarButton(
    navigation,
    navParams = null,
    toComponent,
    icon = null,
    iconSize = 30,
    text = null,
    textSize = 20
  ) {
    return (
      <View>
        <TouchableOpacity
          style={{
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={() => navigation.navigate(toComponent, navParams)}
        >
          {text ? (
            <Text
              style={{ color: 'white', fontSize: textSize, marginRight: 5 }}
            >
              {text}
            </Text>
          ) : null}
          {icon ? (
            <Icon style={{ color: 'white' }} name={icon} size={iconSize} />
          ) : null}
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Landing"
        component={Landing}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="LoginOptions"
        component={LoginOptions}
        options={({ navigation, route }) => ({
          headerLeft: (props) => <LogoTitle {...props} />,
          headerTitle: '',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
      <Stack.Screen
        name="Signup"
        component={Signup}
        options={({ navigation, route }) => ({
          headerTitle: '',
          headerBackTitle: 'Volver',
          headerBackTitleStyle: { color: 'white' },
          headerTintColor: 'white',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
      <Stack.Screen
        name="RequestLogin"
        component={RequestLogin}
        options={({ navigation, route }) => ({
          headerTitle: '',
          headerBackTitle: 'Volver',
          headerBackTitleStyle: { color: 'white' },
          headerTintColor: 'white',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
      <Stack.Screen
        name="LoginConfirm"
        component={LoginConfirm}
        options={({ navigation, route }) => ({
          headerTitle: '',
          headerBackTitle: 'Volver',
          headerBackTitleStyle: { color: 'white' },
          headerTintColor: 'white',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />

      {/* <Stack.Screen name="GuestLogin"     component={GuestLogin}  options={{title: 'Ingresar como invitado', headerBackTitle: 'Volver'}} />
            <Stack.Screen name="Confirmation"   component={ConfirmationInstructions} options={{title: 'Confirmación de cuenta', headerBackTitle: 'Volver'}} />
            <Stack.Screen name="ResetPassword"  component={ResetPassword} options={{title: 'Recuperar contraseña', headerBackTitle: 'Volver'}} /> */}
    </Stack.Navigator>
  )
}

export default AuthStack
