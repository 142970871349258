import React from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import Icon from 'react-native-vector-icons/FontAwesome5'
import { useSelector } from 'react-redux'
// import { Colors } from 'react-native/Libraries/NewAppScreen'
import {
  NavbarButton,
  NavbarActionButton,
  RightBarItems,
} from '../ui_elements/nav/navbar_action_button'
import * as authActions from '../../actions/authActions'

// Admin
import Home from '../../containers/homeContainer'
import PostShow from '../../containers/postShowContainer'
import catSearchContainer from '../../containers/catSearchContainer'

const HomeStack = () => {
  const Stack = createNativeStackNavigator()
  // const isAdmin = useSelector((state) => state.auth.user.role) === 'admin'
  const isGuest = useSelector((state) => state?.auth?.user?.role) === 'guest'

  function LogoTitle() {
    return (
      <View>
        <Image
          style={{
            width: 140,
            height: 50,

            // marginLeft: 10,
            resizeMode: 'contain',
          }}
          source={require('../../assets/logo-white.png')}
        />
      </View>
    )
  }

  function renderNavBarButton(
    navigation,
    navParams = null,
    toComponent,
    icon = null,
    iconSize = 30,
    text = null,
    textSize = 20,
  ) {
    return (
      <View>
        <TouchableOpacity
          style={{
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={() => navigation.navigate(toComponent, navParams)}
        >
          {text ? (
            <Text
              style={{ color: 'white', fontSize: textSize, marginRight: 5 }}
            >
              {text}
            </Text>
          ) : null}
          {icon ? (
            <Icon style={{ color: 'white' }} name={icon} size={iconSize} />
          ) : null}
        </TouchableOpacity>
      </View>
    )
  }

  function renderActionButton(
    onPressAction,
    icon = null,
    iconSize = 30,
    text = null,
    textSize = 20,
  ) {
    return (
      <View>
        <TouchableOpacity
          style={{
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={onPressAction}
        >
          {text ? (
            <Text
              style={{ color: 'white', fontSize: textSize, marginRight: 5 }}
            >
              {text}
            </Text>
          ) : null}
          {icon ? (
            <Icon style={{ color: 'white' }} name={icon} size={iconSize} />
          ) : null}
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="HomeScreen"
        component={Home}
        options={({ navigation, route }) => ({
          headerLeft: (props) => <LogoTitle {...props} />,
          headerTitle: '',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
      <Stack.Screen
        name="PostShowScreen"
        component={PostShow}
        options={({ navigation, route }) => ({
          // headerLeft: props => <LogoTitle {...props} />,
          headerTitle: '',
          headerTintColor: 'white',
          headerBackTitle: 'Volver',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
      <Stack.Screen
        name="catSearch"
        component={catSearchContainer}
        options={({ navigation, route }) => ({
          // headerLeft: props => <LogoTitle {...props} />,
          headerTitle: '',
          headerTintColor: 'white',
          headerBackTitle: 'Volver',
          headerRight: () => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
    </Stack.Navigator>
  )
}

export default HomeStack
