import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Categories from '../screens/Categories'
import * as authActions from '../actions/authActions'

const actions = {
  ...authActions,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  categories: state.categories?.records,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
