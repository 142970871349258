export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';
export const SET_CURRENT_NAVIGATOR = 'SET_CURRENT_NAVIGATOR';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const LOGIN_PENDING = 'LOGIN_PENDING';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_AUTH_ERROR = 'REQUEST_LOGIN_AUTH_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_AUTH_ERROR = 'LOGIN_AUTH_ERROR';

export const GUEST_LOGIN = 'GUEST_LOGIN';
export const GUEST_LOGIN_SUCCESS = 'GUEST_LOGIN_SUCCESS';

export const GET_PROFILE_OPTIONS = 'GET_PROFILE_OPTIONS';
export const GET_PROFILE_OPTIONS_SUCCESS = 'GET_PROFILE_OPTIONS_SUCCESS';
export const GET_PROFILE_OPTIONS_ERROR = 'GET_PROFILE_OPTIONS_ERROR';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';