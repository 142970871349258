export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS'

export const GET_POSTS = 'GET_POSTS'
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS'
export const GET_POSTS_ERROR = 'GET_POSTS_ERROR'

export const GET_FEATURED_POSTS = 'GET_FEATURED_POSTS'
export const GET_FEATURED_POSTS_SUCCESS = 'GET_FEATURED_POSTS_SUCCESS'
export const GET_FEATURED_POSTS_ERROR = 'GET_FEATURED_POSTS_ERROR'

export const GET_FAVORITE_POSTS = 'GET_FAVORITE_POSTS'
export const GET_FAVORITE_POSTS_SUCCESS = 'GET_FAVORITE_POSTS_SUCCESS'
export const GET_FAVORITE_POSTS_ERROR = 'GET_FAVORITE_POSTS_ERROR'

export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE'
export const TOGGLE_FAVORITE_SUCCESS = 'TOGGLE_FAVORITE_SUCCESS'
export const TOGGLE_FAVORITE_ERROR = 'TOGGLE_FAVORITE_ERROR'

export const SIGNOUT = 'SIGNOUT'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR'
