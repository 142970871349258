import { View, Text, TouchableOpacity, Image, Platform } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import Icon from 'react-native-vector-icons/FontAwesome5'
import { useSelector } from 'react-redux'
// import { Colors } from 'react-native/Libraries/NewAppScreen'
import {
  NavbarButton,
  NavbarActionButton,
} from '../ui_elements/nav/navbar_action_button'
import * as authActions from '../../actions/authActions'
import HomeStack from './homeStack'
import FavoritesStack from './favoritesStack'
import SearchStack from './searchStack'
import ProfileStack from './profileStack'
import BeforeQuestion from './beforeQuestion'
import { checkWidth } from '../../constants'

// Admin
import Home from '../../containers/homeContainer'

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

const Tab = createBottomTabNavigator()
const Stack = createNativeStackNavigator()

function MainTabNav() {
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarActiveTintColor: '#7962E0',
        tabBarStyle: {
          display:
            Platform.OS === 'web' ? (checkWidth ? 'flex' : 'none') : 'flex',
          backgroundColor: '#F1F1F1',
          paddingTop: 5,
          paddingBottom: Platform.OS === 'ios' ? 20 : 5,
          height: Platform.OS === 'ios' ? 60 : 50,
        },
      }}
    >
      <Tab.Screen
        name="HomeTab"
        component={HomeStack}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Icon name={'home'} color={color} size={30} />
          ),
        }}
      />
      <Tab.Screen
        name="SearchTab"
        component={SearchStack}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Icon name={'search'} color={color} size={30} />
          ),
        }}
        // listeners={{
        //   tabPress: (e) => {
        //     e.preventDefault()
        //     setVisibleModal(true)
        //   },
        // }}
      />
      <Tab.Screen
        name="FavoritesTab"
        component={FavoritesStack}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Icon name={'star'} color={color} size={30} />
          ),
        }}
      />
      <Tab.Screen
        name="ProfileTab"
        component={ProfileStack}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Icon name={'user-circle'} color={color} size={30} />
          ),
          // tabBarStyle: { display: 'none' },
        }}
      />
    </Tab.Navigator>
  )
}

function Main() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Home" component={MainTabNav} />
      <Stack.Screen name="Location" component={BeforeQuestion} />
    </Stack.Navigator>
  )
}

export default Main

//  const main_place = useSelector((state) => state?.auth?.user?.main_place)
//   return !main_place ? (
//     <LocationStack />
