import React, { useState, useEffect } from 'react'
import { View, Text, Platform } from 'react-native'
import * as ExpoLocation from 'expo-location'
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { isEmpty } from 'lodash'
import CustomText from '../../components/ui_elements/custom_text'
import RoundedButton from '../../components/ui_elements/rounded_button'
import { checkWidth } from '../../constants'

import style from './style'

export default function ChooseMyLocation({
  navigation: { navigate, setOptions },
  auth,
  actions,
}) {
  const [location, setLocation] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [pickLocation, setPickLocation] = useState({})

  useEffect(() => {
    ;(async () => {
      setOptions({ tabBarStyle: { display: 'none' } })
      let { status } = await ExpoLocation.requestForegroundPermissionsAsync()
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied')
        return
      }
      let getLoc = await ExpoLocation.getCurrentPositionAsync({})
      setLocation(getLoc)
    })()
  }, [])

  const getLocation = (data, details) => {
    let locations = []
    let getting = details?.address_components?.map((adds) => {
      let loc = adds.types?.find(
        (type) => type === 'locality' || type === 'country'
      )
      if (loc) {
        return locations.push(adds.long_name)
      }
      return getting
    })
    setPickLocation({ data, details, locations })
  }

  const confirm = () => {
    navigate('Categories')

    if (!isEmpty(pickLocation)) {
      actions?.updateProfile(auth?.access_token, {
        main_place:
          pickLocation?.locations[0] + ', ' + pickLocation?.locations[1],
        main_place_id: pickLocation?.data?.place_id,
      })
      navigate('Categories')
    }
  }

  return (
    <View
      style={[
        style.mainContainer,
        Platform.OS === 'web' && { justifyContent: 'center' },
      ]}
    >
      <View style={[style.mobileVw]}>
        <View style={style.headerTxt}>
          <CustomText title center marginBottom>
            Compártenos tu comuna
          </CustomText>
          <CustomText center>
            Para mostrarte los beneficios más cercanos
          </CustomText>
        </View>

        {Platform.OS === 'web' ? (
          <View style={style.placePicker}>
            <GooglePlacesAutocomplete
              placeholder="Ingresar dirección"
              minLength={2} // minimum length of text to search
              autoFocus={false}
              returnKeyType={'search'} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
              listViewDisplayed="auto" // true/false/undefined
              fetchDetails={true}
              renderDescription={(row) => row.description} // custom description render
              onPress={(data, details = null) => {
                getLocation(data, details)
              }}
              enablePoweredByContainer={false}
              getDefaultValue={() => {
                return ''
              }}
              query={{
                key: 'AIzaSyAZbLDX3s78iUVK_0Aw7pN0zumquM9eB0k',
                language: 'en',
              }}
              requestUrl={{
                useOnPlatform: 'web',
                url: 'https://serve-me-api.herokuapp.com/maps/api',
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }}
              styles={{
                description: {
                  fontWeight: 'bold',
                },
                predefinedPlacesDescription: {
                  color: '#1faadb',
                },
                textInputContainer: {
                  borderWidth: 1,
                  borderRadius: 30,
                  borderColor: '#7962E0',
                  alignItems: 'center',
                  paddingHorizontal: 5,
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 40,
                  backgroundColor: 'white',
                },
                textInput: {
                  height: 25,
                  marginTop: 5,
                },
              }}
              renderLeftButton={() => (
                <Icon
                  style={{ color: '#7962E0' }}
                  name="map-marker-outline"
                  size={30}
                />
              )}
              currentLocation={true}
              currentLocationLabel="Current location"
              nearbyPlacesAPI="GooglePlacesSearch"
              GooglePlacesSearchQuery={{
                rankby: 'distance',
              }}
              debounce={200}
              filterReverseGeocodingByTypes={['locality']} // filter the reverse geocoding results by types - ['locality', 'administrative_area_level_3'] if you want to display only cities
            />
          </View>
        ) : (
          <View />
        )}
      </View>

      {Platform.OS !== 'web' ? (
        <View style={style.placePicker}>
          <GooglePlacesAutocomplete
            placeholder="Ingresar dirección"
            minLength={2} // minimum length of text to search
            autoFocus={false}
            returnKeyType={'search'} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
            listViewDisplayed="auto" // true/false/undefined
            fetchDetails={true}
            renderDescription={(row) => row.description} // custom description render
            onPress={(data, details = null) => {
              getLocation(data, details)
            }}
            enablePoweredByContainer={false}
            getDefaultValue={() => {
              return ''
            }}
            query={{
              key: 'AIzaSyAZbLDX3s78iUVK_0Aw7pN0zumquM9eB0k',
              language: 'en',
            }}
            styles={{
              description: {
                fontWeight: 'bold',
              },
              predefinedPlacesDescription: {
                color: '#1faadb',
              },
              textInputContainer: {
                borderWidth: 1,
                borderRadius: 30,
                borderColor: '#7962E0',
                alignItems: 'center',
                paddingHorizontal: 5,
                paddingTop: 0,
                paddingBottom: 0,
                height: 40,
                backgroundColor: 'white',
              },
              textInput: {
                height: 25,
                marginTop: 5,
              },
            }}
            renderLeftButton={() => (
              <Icon
                style={{ color: '#7962E0' }}
                name="map-marker-outline"
                size={30}
              />
            )}
            currentLocation={true}
            currentLocationLabel="Current location"
            nearbyPlacesAPI="GooglePlacesSearch"
            GooglePlacesSearchQuery={{
              rankby: 'distance',
            }}
            debounce={200}
            filterReverseGeocodingByTypes={['locality']} // filter the reverse geocoding results by types - ['locality', 'administrative_area_level_3'] if you want to display only cities
          />
        </View>
      ) : (
        <View />
      )}

      <View
        style={[style.mobileVw, Platform.OS !== 'web' && style.mapContainer]}
      >
        <MapView
          onMapReady={() => console.log('Map ready')}
          provider={PROVIDER_GOOGLE}
          style={style.map}
          region={
            isEmpty(pickLocation)
              ? {
                  latitude: -33.447487,
                  longitude: -70.673676,
                  latitudeDelta: 0.03,
                  longitudeDelta: 0.09,
                }
              : {
                  latitude: pickLocation?.details?.geometry?.location?.lat,
                  longitude: pickLocation?.details?.geometry?.location?.lng,
                  latitudeDelta: 0.0922,
                  longitudeDelta: 0.0421,
                }
          }
        >
          {Platform.OS !== 'web' ? (
            !isEmpty(pickLocation) ? (
              <Marker
                coordinate={{
                  latitude: pickLocation?.details?.geometry?.location?.lat,
                  longitude: pickLocation?.details?.geometry?.location?.lng,
                }}
              />
            ) : (
              location?.coords?.latitude && (
                <Marker
                  coordinate={{
                    latitude: location?.coords?.latitude,
                    longitude: location?.coords?.longitude,
                  }}
                />
              )
            )
          ) : (
            <View />
          )}
        </MapView>

        {!isEmpty(pickLocation) && Platform.OS === 'web' ? (
          <View style={style.confirmContainer}>
            <View style={{ marginRight: 90 }}>
              <Text
                numberOfLines={1}
                style={{ fontWeight: 'bold', fontSize: 16 }}
              >
                {pickLocation?.locations[0]}
              </Text>
              <Text numberOfLines={1}>{pickLocation?.locations[1]}</Text>
            </View>

            <RoundedButton
              onPress={() => confirm()}
              style={{ width: 80 }}
              textStyling={{ fontSize: 18 }}
            >
              Confirmar
            </RoundedButton>
          </View>
        ) : (
          <View />
        )}
      </View>
      {!isEmpty(pickLocation) && Platform.OS !== 'web' ? (
        <View style={[style.confirmContainer, { bottom: 0 }]}>
          <View style={{ marginRight: 90 }}>
            <Text
              numberOfLines={1}
              style={{ fontWeight: 'bold', fontSize: 16 }}
            >
              {pickLocation?.locations[0]}
            </Text>
            <Text numberOfLines={1}>{pickLocation?.locations[1]}</Text>
          </View>

          <RoundedButton
            onPress={() => confirm()}
            style={{ width: 80 }}
            textStyling={{ fontSize: 18 }}
          >
            Confirmar
          </RoundedButton>
        </View>
      ) : (
        <View />
      )}
    </View>
  )
}
