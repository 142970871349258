import {
  View,
  ScrollView,
  StyleSheet,
  Linking,
  TouchableOpacity,
  Platform,
} from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import Icon5 from 'react-native-vector-icons/FontAwesome5'
import MaterialComIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import CustomText from '../../components/ui_elements/custom_text'
import { checkWidth } from '../../constants'

export default function Questions({
  user,
  onSignout,
  onDeleteAccount,
  navigation,
}) {
  const loginAsUser = async () => {
    await onSignout()
    navigation.navigate('RequestLogin')
  }

  return (
    <ScrollView
      contentContainerStyle={{
        width: Platform.OS === 'web' ? (checkWidth ? '100%' : '40%') : '100%',
        alignSelf: 'center',
      }}
      style={{ backgroundColor: 'white' }}
    >
      <View style={styles.container}>
        <View style={styles.headerVw}>
          <View style={styles.profileImage}>
            <Icon size={45} name="user-circle" color="#7962E0" />
          </View>
          <View style={styles.headerTxtVw}>
            <CustomText title marginBottom>
              {user.username}
            </CustomText>
            <CustomText style={{ marginBottom: 0 }}>{user.email}</CustomText>
            {user?.main_place && (
              <CustomText style={{ marginTop: 0, marginBottom: 0 }}>
                {user?.main_place}
              </CustomText>
            )}
          </View>
        </View>
        <View style={styles.questionsVw}>
          <TouchableOpacity
            style={styles.question}
            key={1}
            onPress={() => Linking.openURL('https://comunicam.cl/contacto/')}
          >
            <View style={styles.questionIconVw}>
              <Icon5 size={30} name="comments" color="#7962E0" />
            </View>
            <View style={styles.questTxtVw}>
              <CustomText marginBottom>{'Contáctanos'}</CustomText>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.question}
            key={2}
            onPress={() =>
              Linking.openURL('https://comunicam.cl/terminos-privacidad-app/')
            }
          >
            <View style={styles.questionIconVw}>
              <Icon5 size={30} name="handshake" color="#7962E0" />
            </View>
            <View style={styles.questTxtVw}>
              <CustomText marginBottom>{'Términos de privacidad'}</CustomText>
            </View>
          </TouchableOpacity>

          {user.role === 'guest' ? (
            <TouchableOpacity
              style={styles.question}
              key={3}
              onPress={() => {
                loginAsUser()
              }}
            >
              <View style={styles.questionIconVw}>
                <Icon5 size={30} name="sign-in-alt" color="#7962E0" />
              </View>
              <View style={styles.questTxtVw}>
                <CustomText marginBottom>{'Iniciar sesión'}</CustomText>
              </View>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.question}
              key={3}
              onPress={() => {
                onSignout()
              }}
            >
              <View style={styles.questionIconVw}>
                <Icon5 size={30} name="sign-out-alt" color="#7962E0" />
              </View>
              <View style={styles.questTxtVw}>
                <CustomText marginBottom>{'Cerrar sesión'}</CustomText>
              </View>
            </TouchableOpacity>
          )}
          {user.id && (
            <TouchableOpacity
              style={styles.question}
              key={4}
              onPress={() => {
                onDeleteAccount()
              }}
            >
              <View style={styles.questionIconVw}>
                <Icon5 size={30} name="trash-alt" color="#7962E0" />
              </View>
              <View style={styles.questTxtVw}>
                <CustomText style={{ color: 'red' }} marginBottom>
                  {'Eliminar mi cuenta'}
                </CustomText>
              </View>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20 },
  headerVw: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  profileImage: {
    backgroundColor: 'white',
    borderRadius: 100,
    alignItems: 'center',
    overflow: 'hidden',
    margin: 10,
  },

  headerTxtVw: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  questionsVw: {
    width: '100%',
    marginTop: 20,
  },
  question: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  questionIconVw: { width: '10%' },
  questTxtVw: {
    paddingBottom: 10,
    marginLeft: 10,
    width: '90%',
    borderBottomWidth: 1,
    borderBottomColor: '#7962E0',
    paddingVertical: 10,
  },
})
