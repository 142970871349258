import React, { useState, useEffect } from 'react'
import {
  TouchableWithoutFeedback,
  StyleSheet,
  View,
  Platform,
} from 'react-native'
import Modal from 'react-native-modal'
import MapView, { PROVIDER_GOOGLE } from 'react-native-maps'
import * as Location from 'expo-location'
import Icon from 'react-native-vector-icons/Fontisto'
import CustomText from '../custom_text'
import RoundedButton from '../rounded_button'
import { checkWidth } from '../../../constants'
export default function LocationModal({
  visibleModal,
  setVisibleModal,
  navigate,
}) {
  const [location, setLocation] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  useEffect(() => {
    ;(async () => {
      let { status } = await Location.requestForegroundPermissionsAsync()
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied')
        return
      }

      let getLoc = await Location.getCurrentPositionAsync({})
      setLocation(getLoc)

      setVisibleModal(false)
    })()
  }, [])
  return (
    <View style={styles.container}>
      <Modal
        isVisible={visibleModal}
        hasBackdrop={false}
        backdropColor="#E6521F"
        backdropOpacity={1}
      >
        <View
          style={{
            backgroundColor: '#E6521F',
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            height: 450,
            borderRadius: 10,
            padding: 20,
            alignSelf: 'center',
          }}
        >
          <TouchableWithoutFeedback onPress={() => setVisibleModal(false)}>
            <View style={{ alignSelf: 'flex-end' }}>
              <Icon style={{ color: 'white' }} name="close-a" size={20} />
            </View>
          </TouchableWithoutFeedback>
          <View style={{ paddingTop: 20 }}>
            <CustomText
              title
              center
              marginBottom
              style={{ color: 'white', fontSize: 22 }}
            >
              Activa tu localización
            </CustomText>
            <CustomText center style={{ color: 'white', fontSize: 12 }}>
              ¡Y te mostraremos los mejores beneficios!
            </CustomText>
          </View>
          <View
            style={{
              alignSelf: 'center',
              borderRadius: 300,
              overflow: 'hidden',
            }}
          >
            <MapView
              onMapReady={() => console.log('Map ready')}
              provider={PROVIDER_GOOGLE}
              style={{ width: 300, height: 300 }}
              region={{
                latitude: -33.447487,
                longitude: -70.673676,
                latitudeDelta: 0.03,
                longitudeDelta: 0.09,
              }}
              pitchEnabled={false}
              rotateEnabled={false}
              scrollEnabled={false}
              zoomEnabled={false}
            />

            <RoundedButton
              style={{
                width: 80,
                alignSelf: 'center',
                position: 'absolute',
                bottom: 10,
                zIndex: 999,
                elevation: 100,
              }}
              textStyling={{ fontSize: 14, textAlign: 'center' }}
              onPress={() => {
                setVisibleModal(false)
                navigate('Location')
              }}
            >
              Activar ahora
            </RoundedButton>
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'lightblue',
    padding: 12,
    margin: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
})
