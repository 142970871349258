import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Favorites from '../screens/favorites'
import * as authActions from '../actions/authActions'
import * as postsActions from '../actions/postsActions'

const actions = {
    ...authActions,
    ...postsActions,
};

const mapStateToProps = state => ({
    auth: state.auth,
    posts: state.posts,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Favorites);