import React, { Component } from 'react'
import {
  ActivityIndicator,
  View,
  Alert,
  ScrollView,
  Dimensions,
  StyleSheet,
  RefreshControl,
  TouchableOpacity,
  Share,
  ImageBackground,
  Linking,
  Platform,
} from 'react-native'
import { Button, Image, Text } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome5'
import _ from 'lodash'
import CustomText from '../../components/ui_elements/custom_text'
import LoginRequired from '../../components/ui_elements/loginRequired'
import RoundedButton from '../../components/ui_elements/rounded_button'
import Requirement from '../../components/ui_elements/requirement'
import { checkWidth } from '../../constants'
export default class PostShow extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    var p = this.props.route.params?.post
    this.setState({ ...p })
    if (this.props.auth.user.role !== 'guest')
      this.props.actions.addUserEvent(this.props.auth.access_token, {
        user_event: { resource_type: 'Post', resource_id: p.id, kind: 'view' },
      })
  }

  _onRefresh() {}

  _renderRequirements() {
    if (this.state.requirements?.length > 0) {
      return (
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          {_.map(this.state.requirements, function (r) {
            return <Requirement text={r.name} key={r.id} />
          })}
          {!!this.state.sex && (
            <Requirement
              text={`Solo para ${
                this.state.sex == 'Mujer' ? 'mujeres' : 'hombres'
              }`}
            />
          )}
          {!!this.state.min_age && (
            <Requirement text={`Edad mínima ${this.state.min_age} años`} />
          )}
          {!!this.state.max_age && (
            <Requirement text={`Edad máxima ${this.state.max_age} años`} />
          )}
          {!!this.state.week_days && (
            <Requirement text={`Aplica ${this.state.week_days}`} />
          )}
        </View>
      )
    }
  }

  isFavorite(p) {
    if (this.props.posts?.favorites) {
      return _.includes(_.map(this.props.posts.favorites, 'id'), p.id)
    } else {
      return false
    }
  }

  onShare = async () => {
    try {
      const result = await Share.share({
        title: this.state.title,
        message: this.state.description,
        // url: ,
      })
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message)
    }
  }

  render() {
    if (!this.props.auth.user.id) {
      return <LoginRequired {...this.props} />
    }
    return (
      <ScrollView
        style={{
          ...styles.mainContainer,
          // marginTop: Platform.OS === 'web' ? '2%' : 0,
        }}
        refreshControl={
          <RefreshControl
            refreshing={this.props.posts?.status == 'loading'}
            onRefresh={this._onRefresh.bind(this)}
          />
        }
        contentContainerStyle={{
          width: Platform.OS === 'web' ? (checkWidth ? '100%' : '70%') : '100%',
          alignSelf: 'center',
        }}
      >
        <View style={styles.headerContainer}>
          <View style={[styles.imgContainer]}>
            {this.state.main_image && (
              <Image
                source={{
                  uri: `${global.BACKEND_HOST}${this.state.main_image}`,
                }}
                style={{ ...styles.mainImg }}
                resizeMode="contain"
                transition={false}
                placeholderStyle={{ backgroundColor: 'white' }}
                containerStyle={{ resizeMode: 'cover' }}
              />
            )}
          </View>
          <View style={{ flex: 8, paddingTop: 10 }}>
            <CustomText title style={{ color: 'white', marginBottom: 0 }}>
              {this.state.title}
            </CustomText>
            <CustomText style={{ color: 'white' }}>
              {this.state.entity}
            </CustomText>
          </View>
          <View style={{ flex: 2, alignItems: 'flex-end' }}>
            <TouchableOpacity
              onPress={this.onShare}
              style={{ paddingLeft: 20, paddingBottom: 5 }}
            >
              <Icon
                name="share-alt"
                size={26}
                style={{ color: 'white' }}
                solid
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                this.props.actions.toggleFavorite(
                  this.props.auth.access_token,
                  this.state.id,
                  this.isFavorite(this.state) ? 'remove' : 'add'
                )
              }
              style={{ paddingLeft: 20, paddingTop: 5 }}
            >
              {_.includes(
                _.map(this.props.posts?.favorites, 'id'),
                this.state.id
              ) ? (
                <Icon
                  name="star"
                  size={26}
                  style={{ color: '#fbc02d' }}
                  solid
                />
              ) : (
                <Icon name="star" size={26} style={{ color: 'white' }} />
              )}
            </TouchableOpacity>
          </View>
        </View>

        <View
          style={[
            {
              paddingHorizontal: 20,
            },
            Platform.OS === 'web' && {
              flexDirection: checkWidth ? 'column' : 'row',
              justifyContent: 'space-between',
              marginTop: 40,
            },
          ]}
        >
          <View>
            <View
              style={[
                styles.requeriments,
                Platform.OS !== 'web' && styles.shadowProp,
              ]}
            >
              <CustomText title>Requisitos</CustomText>
              {this._renderRequirements()}
              {!!this.state.expiration_date && (
                <CustomText style={{ fontSize: 12 }}>
                  Válido hasta el {this.state.expiration_date}
                </CustomText>
              )}
            </View>
          </View>

          {/* <Text>{JSON.stringify(this.state)}</Text> */}
          <View
            style={{
              marginTop: 20,
              width:
                Platform.OS === 'web' ? (checkWidth ? '100%' : '60%') : '100%',
            }}
          >
            <CustomText style={{ paddingTop: 10, textAlign: 'justify' }}>
              {this.state.description}
            </CustomText>
            {!!this.state.small_letter && (
              <CustomText style={{ textAlign: 'justify', fontSize: 10 }}>
                {this.state.small_letter}
              </CustomText>
            )}
            {!!this.state.view_more_btn_link && (
              <RoundedButton
                onPress={() => {
                  var link = this.state.view_more_btn_link
                  Linking.canOpenURL(link).then((supported) => {
                    if (!supported) {
                      Alert.alert('No se puede abrir el enlace')
                    } else {
                      return Linking.openURL(link)
                    }
                  })
                }}
              >
                {!!this.state.view_more_btn_txt
                  ? this.state.view_more_btn_txt
                  : 'Ver más'}
              </RoundedButton>
            )}
          </View>
        </View>
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {},
  imgContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 200,
  },
  mainImg: {
    height: 200,
    width: '100%',
    flexGrow: 1,
    resizeMode: 'cover',
    backgroundColor: 'rgba(0,0,0, 0.50)',
  },
  headerContainer: {
    // backgroundColor: '#9F8AFF',
    flexDirection: 'row',
    flex: 1,
    paddingTop: 20,
    paddingHorizontal: 20,
    paddingBottom: 50,
  },
  requeriments: {
    backgroundColor: 'white',
    borderRadius: 25,
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
    // marginBottom: 20,
    marginTop: Platform.OS === 'web' ? 0 : -30,
  },
  shadowProp:
    Platform.OS === 'ios'
      ? {
          shadowColor: '#171717',
          shadowOffset: { width: -2, height: 4 },
          shadowOpacity: 0.5,
          shadowRadius: 3,
        }
      : {
          elevation: 5,
          shadowColor: '#111111',
        },
})
