const INITIAL_STATE = {
    status: null,
    records: [],
    favorites: [],
    featured: [],
    errors: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_POSTS':
            return {
                ...state,
                status: 'loading',
                errors: null,
            }
        case 'GET_POSTS_SUCCESS':
            return {
                ...state,
                status: 'success',
                records: action.data,
                errors: null,
            }
        case 'GET_POSTS_ERROR':
            return {
                ...state,
                status: 'failure',
                errors: action.data.errors,
            }
        case 'GET_FEATURED_POSTS':
            return {
                ...state,
                status: 'loading',
                errors: null,
            }
        case 'GET_FEATURED_POSTS_SUCCESS':
            return {
                ...state,
                status: 'success',
                featured: action.data,
                errors: null,
            }
        case 'GET_FEATURED_POSTS_ERROR':
            return {
                ...state,
                status: 'failure',
                errors: action.data.errors,
            }
        case 'GET_FAVORITE_POSTS':
            return {
                ...state,
                status: 'loading',
                errors: null,
            }
        case 'GET_FAVORITE_POSTS_SUCCESS':
            return {
                ...state,
                status: 'success',
                favorites: action.data,
                errors: null,
            }
        case 'GET_FAVORITE_POSTS_ERROR':
            return {
                ...state,
                status: 'failure',
                errors: action.data.errors,
            }
        case 'TOGGLE_FAVORITE':
            return {
                ...state,
                status: 'loading',
                errors: null,
            }
        case 'TOGGLE_FAVORITE_SUCCESS':
            return {
                ...state,
                status: 'success',
                favorites: action.data,
                errors: null,
            }
        case 'TOGGLE_FAVORITE_ERROR':
            return {
                ...state,
                status: 'failure',
                errors: action.data.errors,
            }
        default:
            return state;
    }
};