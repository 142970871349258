import React, { Component } from 'react'
import { View, Dimensions, StyleSheet } from 'react-native'
import { Button, Image, Text } from 'react-native-elements'
import RoundedButton from '../../components/ui_elements/rounded_button'

export default class Landing extends Component {

    render() {
        return (
            <View style={styles.mainContainer}>
                <View style={{ alignItems: 'center' }}>
                    <Image
                        source={require('../../assets/logo-white.png')}
                        style={{ width: 300, height: 200 }}
                        resizeMode='contain'
                        transition={true}
                        placeholderStyle={{ backgroundColor: '#7962E0' }}
                        containerStyle={{ resizeMode: 'contain' }}
                    />
                    <RoundedButton
                        style={{ backgroundColor: '#E2501E'}}
                        onPress={() => this.props.navigation.navigate('LoginOptions')}>
                        Comenzar
                    </RoundedButton>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        justifyContent: 'center',
        height: Dimensions.get('window').height,
        paddingRight: 30,
        paddingLeft: 30,
        backgroundColor: '#7962E0',
    },
});