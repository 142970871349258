const INITIAL_STATE = {
  status: null,
  user: {
    id: null,
    username: null,
    first_name: null,
    last_name: null,
    email: null,
    city_id: null,
    role: null,
    birth_date: null,
    home_region: null,
    sex: null,
    ocupation: null,
    education_level: null,
    should_complete_profile: null,
  },
  profile_options: null,
  access_token: null,
  errors: null,
  msg: null,
  reset_password_status: null,
  reset_password_token: null,
  current_navigator: 'main',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STATUS_SUCCESS':
      return {
        ...state,
        status: 'success',
      }
    case 'SET_CURRENT_NAVIGATOR':
      return {
        ...state,
        current_navigator: action.data,
      }
    case 'SIGNUP':
      return {
        ...state,
        status: 'loading',
        errors: null,
      }
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        status: 'success',
        user: action.data,
        errors: null,
      }
    case 'SIGNUP_ERROR':
      return {
        ...state,
        status: 'failure',
        errors: action.data.errors,
      }
    case 'LOGIN_PENDING':
      return {
        ...state,
        status: 'login_pending',
        user: action.data,
        errors: null,
      }
    case 'REQUEST_LOGIN':
      return {
        ...state,
        status: 'loading',
        user: {
          email: action.data.email,
        },
        errors: null,
      }
    case 'REQUEST_LOGIN_SUCCESS':
      return {
        ...state,
        status: 'success',
        errors: null,
      }
    case 'REQUEST_LOGIN_AUTH_ERROR':
      return {
        ...state,
        status: 'auth_error',
        errors: action.data.errors,
      }
    case 'LOGIN':
      return {
        ...state,
        status: 'loading',
        errors: null,
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        status: 'success',
        access_token: action.data.authToken,
        user: action.data,
        errors: null,
      }
    case 'LOGIN_AUTH_ERROR':
      return {
        ...state,
        status: 'auth_error',
        errors: [action.data.error],
      }
    case 'GUEST_LOGIN':
      return {
        ...state,
        status: 'loading',
        errors: null,
      }
    case 'GUEST_LOGIN_SUCCESS':
      return {
        ...state,
        status: 'success',
        access_token: 'GUEST_TOKEN',
        user: {
          id: null,
          first_name: 'Invitado',
          username: 'Invitado',
          last_name: null,
          email: null,
          city_id: null,
          phone: null,
          newsletter_subscription: false,
          role: 'guest',
        },
        errors: null,
      }
    case 'GET_PROFILE_OPTIONS':
      return {
        ...state,
        status: 'loading',
        errors: null,
      }
    case 'GET_PROFILE_OPTIONS_SUCCESS':
      return {
        ...state,
        status: 'success',
        profile_options: action.data,
        errors: null,
      }
    case 'GET_PROFILE_OPTIONS_ERROR':
      return {
        ...state,
        status: 'error',
        errors: action.data.errors,
      }
    case 'UPDATE_PROFILE':
      return {
        ...state,
        status: 'loading',
        errors: null,
      }
    case 'UPDATE_PROFILE_SUCCESS':
      return {
        ...state,
        status: 'success',
        user: action.data,
        errors: null,
      }
    case 'UPDATE_PROFILE_ERROR':
      return {
        ...state,
        status: 'error',
        errors: action.data.errors,
      }
    case 'RESET_PASSWORD':
      return {
        ...state,
        reset_password_status: 'loading',
        errors: null,
      }
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        reset_password_status: 'success',
        errors: null,
      }
    case 'RESET_PASSWORD_ERROR':
      return {
        ...state,
        reset_password_status: 'error',
        errors: action.data.errors,
      }
    case 'GET_RESET_PASSWORD_FULL_CODE':
      return {
        ...state,
        reset_password_status: 'loading',
        reset_password_token: null,
        errors: null,
      }
    case 'GET_RESET_PASSWORD_FULL_CODE_SUCCESS':
      return {
        ...state,
        reset_password_status: 'success2',
        reset_password_token: action.data.reset_password_token,
        errors: null,
      }
    case 'GET_RESET_PASSWORD_FULL_CODE_ERROR':
      return {
        ...state,
        reset_password_status: 'error',
        reset_password_token: null,
        errors: action.data.errors,
      }
    case 'CHANGE_PASSWORD':
      return {
        ...state,
        reset_password_status: 'loading',
        errors: null,
      }
    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        reset_password_status: 'success3',
        errors: null,
      }
    case 'CHANGE_PASSWORD_ERROR':
      return {
        ...state,
        reset_password_status: 'error',
        errors: action.data.errors,
      }
    case 'SIGNOUT':
      return {
        ...state,
        status: 'loading',
        errors: null,
      }
    case 'SIGNOUT_SUCCESS':
      return {
        ...INITIAL_STATE,
        status: 'success',
        errors: null,
      }
    case 'SIGNOUT_ERROR':
      return {
        ...state,
        status: 'signout_error',
        errors: null,
      }
    default:
      return state
  }
}
