import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator, Image } from 'react-native'

const SquareButton = props => {
    return (
        <TouchableOpacity onPress={props.onPress} disabled={props.loading} style={props.containerStyle}>
            <View style={{ ...styles.button, ...props.style }}>
                {props.loading && <ActivityIndicator size="small" color="white" style={{marginRight: 10}} />}
                {props.icon ?
                    <Image
                        source={props.icon}
                        style={{ ...styles.icon }}
                        resizeMode={'contain'}
                        transition={false}
                        placeholderStyle={{ backgroundColor: 'none' }}
                    />
                : null}
                <Text style={{ ...styles.buttonText, ...props.textStyling }}>
                    {props.children}
                </Text>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#DADADA',
        borderRadius: 10,
        paddingVertical: 0,
        paddingHorizontal: 5,
        marginBottom: 10,
        minHeight: 50,
        alignItems: 'center',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    },
    icon: {
        height: 50,
        width: 50,
    },
    buttonText: {
        color: '#606060',
        fontSize: 16,
        fontWeight: 'bold',
    }
})

export default SquareButton