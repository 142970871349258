import * as types from '../actionTypes/categories';
import { fetchWithTimeout } from '../components/fetchWithTimeout';

export function setStatusSuccess() {
    return async dispatch => { dispatch({ type: types.SET_STATUS_SUCCESS }) }
}

export function getCategories(token) {
    return async dispatch => {
        dispatch({ type: types.GET_CATEGORIES });
        try {
            let response = await fetchWithTimeout(`${global.API_URL}/categories`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            });
            if (response.status === 200) {
                const resp = await response.json();
                dispatch({ type: types.GET_CATEGORIES_SUCCESS, data: resp });
            } else if (response.status === 403) {
                dispatch({ type: types.GET_CATEGORIES_ERROR, data: { errors: { 'Forbidden': ["You don't have permissions"] } }});
            } else {
                dispatch({ type: types.GET_CATEGORIES_ERROR, data: { errors: { 'Connection error': [`Response code ${response.status}`] } }});
            }
        } catch (error) {
            dispatch({ type: types.GET_CATEGORIES_ERROR, data: { errors: { 'Connection error': [`Unknown`] } }});
        }
    }
};