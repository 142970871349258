import React from 'react'
import { View, Text } from 'react-native'
import RoundedButton from './rounded_button'

const LoginRequired = (props) => {
  const loginAsUser = async () => {
    await props.actions.signout()
    props.navigation.navigate('RequestLogin')
  }
  return (
    <View style={{ marginTop: 30 }}>
      <Text style={{ textAlign: 'center' }}>
        Necesitas iniciar sesión para ingresar a esta seccíon
      </Text>
      <RoundedButton
        style={{
          marginTop: 30,
          marginBottom: 20,
          textAlign: 'center',
          width: 260,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        onPress={() => {
          loginAsUser()
        }}
      >
        Iniciar Sesión
      </RoundedButton>
    </View>
  )
}

export default LoginRequired
