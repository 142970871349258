import React, { Component } from 'react'
import {
  ActivityIndicator,
  View,
  ScrollView,
  Dimensions,
  StyleSheet,
  RefreshControl,
  Alert,
  Platform,
} from 'react-native'
import { Button, Image, Text } from 'react-native-elements'
import CustomText from '../components/ui_elements/custom_text'
import SquareButton from '../components/ui_elements/square_button'
import B from '../components/ui_elements/text_styling'
import PostCard from '../components/ui_elements/post_card'
import Banner from '../components/ui_elements/banner'
import LocationModal from '../components/ui_elements/LocationModal'
import _ from 'lodash'
import ModalBottomMenu from '../components/ui_elements/ModalBottomMenu'
import { checkWidth } from '../constants'

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = { modalVisible: false, locationModal: false }
  }

  componentDidMount() {
    // this.props.navigation.navigate('Location')
    // this.props.actions.signout()
    this.props.actions.getCategories(this.props.auth.access_token)
    this.props.actions.getPosts(this.props.auth.access_token)
    this.props.actions.getFeaturedPosts(this.props.auth.access_token)
    if (!this.props.auth.user.role === 'guest')
      this.props.actions.getFavorites(this.props.auth.access_token)
    this.setState({ locationModal: !this.props.auth?.user?.main_place })
  }

  _onRefresh() {
    this.props.actions.getCategories(this.props.auth.access_token)
    this.props.actions.getPosts(this.props.auth.access_token)
    this.props.actions.getFeaturedPosts(this.props.auth.access_token)
    if (!this.props.auth.user.role === 'guest')
      this.props.actions.getFavorites(this.props.auth.access_token)
  }

  isFavorite(p) {
    if (this.props.posts?.favorites) {
      return _.includes(_.map(this.props.posts.favorites, 'id'), p.id)
    } else {
      return false
    }
  }

  _renderFeaturedPost(pos) {
    // console.log(`----- POS = ${pos} -------`)
    if (pos >= 0 && this.props.posts?.featured?.length > pos) {
      var p = this.props.posts?.featured[pos]
      return (
        <Banner
          title={p.title}
          subtitle={p.entity}
          img={p.main_image}
          onPress={() =>
            this.props.navigation.navigate('HomeTab', {
              screen: 'PostShowScreen',
              params: { post: p },
            })
          }
        />
      )
    }
  }

  _categoryHasPosts(category) {
    var posts = _.filter(this.props.posts?.records, function (r) {
      return _.includes(r.category_ids, category.id)
    })
    return posts.length > 0
  }

  _renderPostsSlider(category) {
    return (
      <ScrollView horizontal={true}>
        {_.filter(this.props.posts?.records, function (r) {
          return _.includes(r.category_ids, category.id)
        }).map((e, i) => {
          return (
            <PostCard
              key={i}
              favorite={this.isFavorite(e)}
              onPress={() =>
                this.props.navigation.navigate('HomeTab', {
                  screen: 'PostShowScreen',
                  params: { post: e },
                })
              }
              onPressFavorite={() =>
                this.props.actions.toggleFavorite(
                  this.props.auth.access_token,
                  e.id,
                  this.isFavorite(e) ? 'remove' : 'add'
                )
              }
              image={e?.main_image}
              title={e.entity}
              body={e.title}
              discount={e?.discount}
              discountShow={e?.kind === 'discount'}
            />
          )
        })}
      </ScrollView>
    )
  }

  _renderCategories() {
    if (this.props.categories?.records) {
      return (
        <View style={{ paddingBottom: 80 }}>
          {this.props.categories?.records.map((c, i) => {
            return (
              <View key={i}>
                {i % 3 == 0 && this._renderFeaturedPost(i / 3)}
                {this._categoryHasPosts(c) && (
                  <View style={{ marginVertical: 10 }}>
                    <CustomText
                      title
                      style={{
                        paddingHorizontal: 10,
                      }}
                    >
                      {c.name}
                    </CustomText>
                    {this._renderPostsSlider(c)}
                  </View>
                )}
              </View>
            )
          })}
        </View>
      )
    }
  }

  render() {
    const setVisibleModal = (bool) => {
      this.setState({ modalVisible: bool })
    }
    const toggle = (bool) => {
      this.setState({ locationModal: bool })
    }
    return (
      <ScrollView
        style={styles.mainContainer}
        refreshControl={
          <RefreshControl
            refreshing={this.props.posts?.status == 'loading'}
            onRefresh={this._onRefresh.bind(this)}
          />
        }
        contentContainerStyle={{
          width: Platform.OS === 'web' ? (checkWidth ? '100%' : '70%') : '100%',
          alignSelf: 'center',
        }}
      >
        <View
          style={{
            flex: 10,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
            paddingTop: 20,
          }}
        >
          <SquareButton
            containerStyle={{ flex: 4, paddingRight: 5 }}
            icon={require('../assets/benefits_kind/discounts.png')}
            onPress={() =>
              this.props.navigation.navigate('catSearch', 'private')
            }
          >
            Privados
          </SquareButton>
          <SquareButton
            containerStyle={{ flex: 4, paddingRight: 5 }}
            icon={require('../assets/benefits_kind/benefits.png')}
            onPress={() =>
              this.props.navigation.navigate('catSearch', 'public')
            }
          >
            Públicos
          </SquareButton>
          <SquareButton
            containerStyle={{ flex: 2 }}
            textStyling={{ fontSize: 30, color: '#7962E0' }}
            style={{ paddingHorizontal: 0, paddingVertical: 0 }}
            onPress={() => setVisibleModal(true)}
          >
            +
          </SquareButton>
        </View>
        {this._renderCategories()}
        <LocationModal
          visibleModal={this.state.locationModal}
          setVisibleModal={toggle}
          navigate={this.props.navigation.navigate}
        />
        <ModalBottomMenu
          modalVisible={this.state.modalVisible}
          setVisibleModal={setVisibleModal}
          navigate={(item) =>
            this.props.navigation.navigate('catSearch', { ...item })
          }
        />
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: 'white',
    // paddingTop: 20,
    // marginTop: 20,
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 13,
  },
})
