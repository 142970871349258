import { useState } from 'react'
import { View, FlatList, Dimensions, Alert, Platform } from 'react-native'
import { isEmpty } from 'lodash'
import CustomText from '../../components/ui_elements/custom_text'
import RoundedButton from '../../components/ui_elements/rounded_button'
import CategoryCard from '../../components/ui_elements/categoryCard'
import style from './style'
import { checkWidth } from '../../constants'
const width = Dimensions.get('window').width
export default function Categories({
  categories,
  navigation: { navigate },
  auth,
  actions,
}) {
  const [selectedItems, setSelectedItems] = useState({})

  const select = (item) => {
    setSelectedItems({ ...selectedItems, [item?.id]: item })
  }
  const unSelect = (id) => {
    let items = { ...selectedItems }
    delete items[id]
    setSelectedItems({ ...items })
  }
  const toggle = (item) => {
    if (selectedItems[item?.id]) {
      unSelect(item?.id)
      return
    }
    select(item)
  }
  const submit = () => {
    const preferred_category_ids = Object.keys(selectedItems)
    if (!isEmpty(preferred_category_ids)) {
      actions?.updateProfile(auth?.access_token, { preferred_category_ids })
      navigate('HomeScreen')
      return
    }
    Alert.alert('Precaución', 'Por favor seleccione categorías')
  }

  return (
    <View style={style.mainContainer}>
      <View style={style.headerTxt}>
        <CustomText title center marginBottom>
          Selecciona de acuerdo a tus preferencias
        </CustomText>
        <CustomText center>Para mostrarte lo que va contigo</CustomText>
      </View>
      <View
        style={{
          width: Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
          alignSelf: 'center',
        }}
      >
        <FlatList
          style={{
            padding: width < 390 ? 0 : 15,
          }}
          data={categories}
          numColumns={Platform.OS === 'web' ? (checkWidth ? 3 : 6) : 3}
          keyExtractor={(item, index) => item.id}
          renderItem={({ item, index }) => {
            return (
              <CategoryCard
                item={item}
                toggle={toggle}
                selectedItems={selectedItems}
              />
            )
          }}
          ListFooterComponent={() => (
            <View style={style.confirmVw}>
              <RoundedButton
                onPress={() => submit()}
                style={{ width: 80 }}
                textStyling={{ fontSize: 18 }}
              >
                Confirmar
              </RoundedButton>
            </View>
          )}
          contentContainerStyle={{ paddingBottom: 200 }}
        />
      </View>
    </View>
  )
}
