import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as postsActions from '../actions/postsActions'
import categoriesSearch from '../screens/categoriesSearch'

const actions = {
  ...authActions,
  ...postsActions,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  posts: state.posts,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(categoriesSearch)
