import React, { Component } from 'react'
import {
  View,
  Dimensions,
  StyleSheet,
  TextInput,
  Alert,
  Platform,
} from 'react-native'
import { CheckBox, Text } from 'react-native-elements'
import CustomText from '../../components/ui_elements/custom_text'
import RoundedButton from '../../components/ui_elements/rounded_button'
import _ from 'lodash'
import { checkWidth } from '../../constants'
export default class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      newsletterSubscription: true,
      termsAgree: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.auth.status === 'login_pending' &&
      prevProps.auth.status !== 'login_pending'
    ) {
      this.props.navigation.navigate('RequestLogin')
    } else if (
      this.props.auth.status === 'success' &&
      prevProps.auth.status !== 'success'
    ) {
      this.props.navigation.navigate('LoginConfirm')
    }
  }

  _attrHasError = (attrKey) => {
    var errs = this.props.auth.errors
    return errs && _.includes(Object.keys(errs), attrKey)
  }

  _renderErrors = (attrKey) => {
    if (this._attrHasError(attrKey)) {
      var errs = this.props.auth.errors
      return (
        <View
          style={{
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            alignSelf: 'center',
          }}
        >
          {errs[attrKey].map((msg) => {
            return (
              <Text style={styles.inputErrorMsg} hidden={true}>
                {_.capitalize(`${msg}`)}
              </Text>
            )
          })}
        </View>
      )
    }
  }

  _signup = () => {
    if (!this.state.username || !this.state.email) {
      Alert.alert(
        'Información incompleta',
        'Debes ingresar un nombre de usuario y correo válidos para continuar.',
        [{ text: 'OK' }]
      )
    } else if (!this.state.termsAgree) {
      Alert.alert(
        'Términos y Condiciones',
        'Debes aceptar los términos y condiciones para continuar.',
        [{ text: 'OK' }]
      )
    } else {
      this.props.actions.signup(this.state)
    }
  }

  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={{ alignItems: 'center' }}>
          <CustomText title center>
            Crea tu cuenta
          </CustomText>
          <CustomText center>¡Para acceder a los beneficios!</CustomText>
        </View>
        <View style={styles.inputContainer}>
          <TextInput
            type="username"
            name="username"
            style={styles.inputField}
            autoCorrect={false}
            placeholder="Nombre de usuario"
            ref={(el) => {
              this.username = el
            }}
            onChangeText={(username) => this.setState({ username })}
            value={this.state.username}
          />
        </View>
        {this._renderErrors('username')}

        <View style={styles.inputContainer}>
          <TextInput
            type="email"
            name="email"
            style={styles.inputField}
            autoCorrect={false}
            placeholder="Email"
            ref={(el) => {
              this.email = el
            }}
            onChangeText={(email) => this.setState({ email })}
            value={this.state.email}
            autoCapitalize="none"
          />
        </View>
        {this._renderErrors('email')}

        <View
          style={{
            alignItems: 'flex-start',
            marginTop: 10,
            marginBottom: 10,
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            alignSelf: 'center',
          }}
        >
          <CheckBox
            checked={this.state.newsletterSubscription}
            onPress={() =>
              this.setState({
                newsletterSubscription: !this.state.newsletterSubscription,
              })
            }
            containerStyle={styles.checkbox}
            title="Quiero que me notifiquen nuevos beneficios y noticias"
          />
          <CheckBox
            checked={this.state.termsAgree}
            onPress={() =>
              this.setState({ termsAgree: !this.state.termsAgree })
            }
            containerStyle={styles.checkbox}
            title="Acepto los términos y condiciones"
          />
        </View>

        <View
          style={{
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            alignSelf: 'center',
          }}
        >
          <RoundedButton
            style={{
              marginTop: 50,
              marginBottom: 20,
              width: 280,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            onPress={() => this._signup()}
            loading={this.props.auth.status == 'loading'}
          >
            Confirmar
          </RoundedButton>
          <Text
            style={{ textAlign: 'center' }}
            onPress={() => this.props.navigation.navigate('RequestLogin')}
          >
            Si ya tienes cuenta haz aquí
          </Text>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    height: Dimensions.get('window').height,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: '#7962E0',
    borderRadius: 50,
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginTop: 15,
    width: Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
    alignSelf: 'center',
  },
  inputField: {
    fontSize: 16,
  },
  inputErrorMsg: {
    color: 'red',
  },
  checkbox: {
    backgroundColor: 'white',
    borderWidth: 0,
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
  },
})
