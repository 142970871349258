import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
  Dimensions,
} from 'react-native'
const width = Dimensions.get('window').width
export default function BottomCategoryCard({ item, onPress }) {
  return (
    <TouchableWithoutFeedback onPress={() => onPress()}>
      <View
        style={{
          margin: 5,
          alignItems: 'center',
          width: width / 5,
          paddingHorizontal: 10,
          paddingVertical: 5,
          backgroundColor: '#ECE9FA',
          borderRadius: 10,
        }}
      >
        <View
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            marginBottom: 5,
          }}
        >
          {item?.icon && (
            <Image
              source={{ uri: global.BACKEND_HOST + item?.icon }}
              style={{ width: 30, height: 30 }}
            />
          )}
        </View>

        <View style={{ alignItems: 'center' }}>
          <Text numberOfLines={1} style={{ fontSize: 10 }}>
            {item?.name}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}
