import React, { Component } from 'react'
import {
  View,
  Dimensions,
  StyleSheet,
  TextInput,
  Alert,
  Platform,
} from 'react-native'
import { Button, Image, Text } from 'react-native-elements'
import RoundedButton from '../../components/ui_elements/rounded_button'
import CustomText from '../../components/ui_elements/custom_text'
import Toast from 'react-native-toast-message'
import _ from 'lodash'
import { checkWidth } from '../../constants'

export default class RequestLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  componentDidMount() {
    if (this.props.auth?.user?.email) {
      this.setState({ email: this.props.auth.user.email })
      setTimeout(function () {
        Toast.show({
          type: 'success',
          text1: 'Tu cuenta ya existe',
          text2: 'Inicia sesión con ella.',
        })
      }, 500)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.auth.status === 'success' &&
      prevProps.auth.status !== 'success'
    ) {
      this.props.navigation.navigate('LoginConfirm')
    }
  }

  _login = () => {
    if (this.state.email == '' || this.state.email == null) {
      Alert.alert(
        'Correo en blanco',
        'Debes ingresar un correo válido para continuar.',
        [{ text: 'OK' }]
      )
    } else {
      this.props.actions.requestLogin(this.state)
    }
  }

  _attrHasError = (attrKey) => {
    var errs = this.props.auth.errors
    return errs && _.includes(Object.keys(errs), attrKey)
  }

  _renderErrors = (attrKey) => {
    if (this._attrHasError(attrKey)) {
      var errs = this.props.auth.errors
      return (
        <View
          style={{
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            alignSelf: 'center',
          }}
        >
          {errs[attrKey].map((msg) => {
            return (
              <Text style={styles.inputErrorMsg} hidden={true}>
                {_.capitalize(`${msg}`)}
              </Text>
            )
          })}
        </View>
      )
    }
  }

  render() {
    return (
      <View style={styles.mainContainer}>
        <Toast position="top" topOffset={20} />
        <View style={{ alignItems: 'center' }}>
          <CustomText title center>
            Inicia sesión
          </CustomText>
          <CustomText center>¡Para acceder a los beneficios!</CustomText>
        </View>

        <View style={styles.inputContainer}>
          <TextInput
            type="email"
            name="email"
            style={styles.inputField}
            autoCorrect={false}
            placeholder="Email"
            ref={(el) => {
              this.email = el
            }}
            onChangeText={(email) => this.setState({ email })}
            value={this.state.email}
            autoCapitalize="none"
          />
        </View>
        {this._renderErrors('email')}

        <View
          style={{
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            alignSelf: 'center',
          }}
        >
          <RoundedButton
            style={{
              marginTop: 50,
              marginBottom: 20,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            onPress={() => this._login()}
            loading={this.props.auth.status == 'loading'}
          >
            Confirmar
          </RoundedButton>
          <Text
            style={{ textAlign: 'center' }}
            onPress={() => this.props.navigation.navigate('Signup')}
          >
            Si no tienes cuenta crea una aquí
          </Text>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    height: Dimensions.get('window').height,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: '#7962E0',
    borderRadius: 50,
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginTop: 15,
    width: Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
    alignSelf: 'center',
  },
  inputField: {
    fontSize: 16,
  },
  inputErrorMsg: {
    color: 'red',
  },
})
