import React from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import Icon from 'react-native-vector-icons/FontAwesome5'

import LocationContainer from '../../containers/LocationContainer'
import CategoriesContainer from '../../containers/categoriesContainer'
import { RightBarItems } from '../ui_elements/nav/navbar_action_button'

const BeforeQuestion = () => {
  const Stack = createStackNavigator()

  function LogoTitle() {
    return (
      <View>
        <Image
          style={{
            width: 140,
            height: 70,
            marginLeft: 10,
            resizeMode: 'contain',
          }}
          source={require('../../assets/logo-white.png')}
        />
      </View>
    )
  }

  function renderNavBarButton(
    navigation,
    navParams = null,
    toComponent,
    icon = null,
    iconSize = 30,
    text = null,
    textSize = 20
  ) {
    return (
      <View>
        <TouchableOpacity
          style={{
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={() => navigation.navigate(toComponent, navParams)}
        >
          {text ? (
            <Text
              style={{
                color: 'white',
                fontSize: textSize,
                marginRight: 5,
              }}
            >
              {text}
            </Text>
          ) : null}
          {icon ? (
            <Icon style={{ color: 'white' }} name={icon} size={iconSize} />
          ) : null}
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Location"
        component={LocationContainer}
        options={({ navigation, route }) => ({
          headerTitle: '',
          headerBackTitle: 'Volver',
          headerBackTitleStyle: { color: 'white' },
          headerTintColor: 'white',
          headerLeft: (props) => <LogoTitle {...props} />,
          headerRight: (props) => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
      <Stack.Screen
        name="Categories"
        component={CategoriesContainer}
        options={({ navigation, route }) => ({
          headerTitle: '',
          headerBackTitle: 'Volver',
          headerBackTitleStyle: { color: 'white' },
          headerTintColor: 'white',
          headerLeft: (props) => <LogoTitle {...props} />,
          headerRight: (props) => (
            <RightBarItems navigation={navigation} route={route} />
          ),
          headerStyle: { backgroundColor: '#7962E0' },
        })}
      />
    </Stack.Navigator>
  )
}

export default BeforeQuestion
