import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'

const RoundedButton = props => {
    return (
        <TouchableOpacity onPress={props.onPress} disabled={props.loading} style={props.containerStyle}>
            <View style={{ ...styles.button, ...props.style }}>
                {props.loading && <ActivityIndicator size="small" color="white" style={{marginRight: 10}} />}
                <Text style={{ ...styles.buttonText, ...props.textStyling }}>
                    {props.children}
                </Text>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#7962E0',
        borderRadius: 50,
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginBottom: 10,
        minWidth: 150,
        alignItems: 'center',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    buttonText: {
        color: 'white',
        fontSize: 24,
        fontWeight: 'bold',
    }
})

export default RoundedButton