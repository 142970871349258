import React from 'react'
import { View, TouchableOpacity, Linking, Platform, Alert } from 'react-native'
import { Text } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome5'
import { useSelector } from 'react-redux'
import { checkWidth } from '../../../constants'

export const NavbarActionButton = (props) => {
  return (
    <View>
      <TouchableOpacity
        style={{
          marginRight: 20,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ...props.style,
        }}
        onPress={props.onClick}
      >
        {!!props.text ? (
          <Text
            style={{
              color: 'white',
              fontSize: props.textSize || 20,
              marginRight: 5,
            }}
          >
            {props.text}
          </Text>
        ) : null}
        {!!props.icon ? (
          <Icon
            style={{ color: 'white' }}
            name={props.icon}
            size={props.iconSize || 30}
          />
        ) : null}
      </TouchableOpacity>
    </View>
  )
}

export const NavbarButton = (props) => {
  return (
    <NavbarActionButton
      style={{ ...props.style }}
      onPress={() =>
        props.navigationInstance.navigate(props.toComponent, props.navParams)
      }
      fontSize={props.textSize}
      text={props.text}
      icon={props.icon}
      iconSize={props.iconSize}
    />
  )
}

export const RightBarItems = (props) => {
  const isAuth = useSelector(
    (state) => !!state.auth.access_token && !!state.auth.user
  )
  const {
    navigation: { navigate },
    route: { name },
  } = props
  return (
    <View style={{ flexDirection: 'row' }}>
      {props?.isGuest ? (
        <Text
          style={{
            color: 'white',
            fontSize: 18,
            marginRight: 5,
            marginRight: 20,
          }}
        >
          Invitado
        </Text>
      ) : null}
      {Platform.OS === 'web' && !checkWidth && isAuth && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: 400,
            height: '40',
            alignItems: 'center',
            marginRight: 30,
          }}
        >
          <Icon
            name={'home'}
            color={
              name === 'HomeScreen'
                ? 'white'
                : name === 'PostShowScreen'
                ? 'white'
                : name === 'catSearch'
                ? 'white'
                : '#EAEAF2'
            }
            size={30}
            onPress={() =>
              navigate(name === 'HomeTab' ? 'HomeTab' : 'HomeScreen')
            }
          />
          <Icon
            name={'search'}
            color={name === 'Search' ? 'white' : '#EAEAF2'}
            size={30}
            onPress={() => navigate('SearchTab')}
          />
          <Icon
            name={'star'}
            color={name === 'Favorites' ? 'white' : '#EAEAF2'}
            size={30}
            onPress={() => navigate('FavoritesTab')}
          />

          <Icon
            name={'user-circle'}
            color={name === 'ProfileSetup' ? 'white' : '#EAEAF2'}
            size={30}
            onPress={() => navigate('ProfileTab')}
          />
        </View>
      )}
      <NavbarActionButton
        onClick={() => {
          link = 'whatsapp://send?phone=56969033718'
          Linking.canOpenURL(link).then((supported) => {
            if (!supported) {
              Alert.alert(
                'Por favor descarga Whatsapp para chatear con nosotros'
              )
            } else {
              return Linking.openURL(link)
            }
          })
        }}
        icon={'question-circle'}
        iconSize={30}
      />
    </View>
  )
}
