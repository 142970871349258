import React, { Component } from 'react'
import {
  ActivityIndicator,
  View,
  TextInput,
  ScrollView,
  FlatList,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { Button, Image, Text } from 'react-native-elements'
import CustomText from '../components/ui_elements/custom_text'
import SquareButton from '../components/ui_elements/square_button'
import B from '../components/ui_elements/text_styling'
import PostCard from '../components/ui_elements/post_card'
import Banner from '../components/ui_elements/banner'
import _ from 'lodash'
import { searchPost, recentSearches } from '../actions/postsActions'
const width = Dimensions.get('window').width
import { checkWidth } from '../constants'

export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      page: 1,
      totalPages: 1,
      records: [],
      recentSch: { most_viewed: [], recent_searchs: [] },
      loading: false,
    }
  }
  componentDidMount() {
    if (this.props.auth.user.role !== 'guest')
      recentSearches(this.props.auth.access_token).then((res) => {
        this.setState({
          recentSch: {
            most_viewed: res.posts,
            recent_searchs: res.meta.recent_searchs,
          },
        })
      })
  }

  isFavorite(p) {
    if (this.props.posts?.favorites) {
      return _.includes(_.map(this.props.posts.favorites, 'id'), p.id)
    } else {
      return false
    }
  }

  render() {
    const { loading, search, records, recentSch, page, totalPages } = this.state
    const token = this.props.auth.access_token
    const onSearch = async (txt) => {
      this.setState({ search: txt, records: _.isEmpty(txt) ? [] : records })
      if (txt?.length > 2) {
        searchPost(token, txt, page).then((res) => {
          this.setState({
            records: res.posts,
            totalPages: res?.meta?.total_pages,
          })
        })
        return
      }
      if (_.isEmpty(txt)) {
        recentSearches(token).then((res) =>
          this.setState({
            recentSch: res,
            page: 1,
            totalPages: 1,
          })
        )
        return
      }
    }

    const pagination = () => {
      const morePage = page + 1
      if (morePage < totalPages) {
        this.setState({ loading: !_.isEmpty(search) })
        searchPost(token, search, morePage).then((res) => {
          this.setState({
            records: [...records, ...res.posts],
            page: morePage,
            totalPages: res?.meta?.total_pages,
            loading: false,
          })
        })
      }
    }
    return (
      <View style={styles.mainContainer}>
        <View style={styles.searchHeadVw}>
          <View style={styles.inputTxtVw}>
            <TextInput
              placeholder="¿Que buscas hoy?"
              value={this.state.search}
              onChangeText={(search) => this.setState({ search })}
              placeholderTextColor={'#666666'}
              onSubmitEditing={() => onSearch(this.state.search)}
            />
          </View>
          <TouchableOpacity
            style={styles.favour}
            onPress={() => onSearch(this.state.search)}
          >
            <Icon size={30} name="search" color="#7962E0" />
          </TouchableOpacity>
        </View>
        <FlatList
          data={records}
          ListEmptyComponent={() => (
            <ScrollView style={{ paddingHorizontal: 10 }}>
              {recentSch?.recent_searchs?.lenght > 0 && (
                <View>
                  <CustomText title>Recientes</CustomText>
                  {recentSch?.recent_searchs?.map((rec, i) => (
                    <TouchableOpacity
                      key={i}
                      onPress={() => {
                        this.setState({ search: rec?.search_term })
                        onSearch(rec?.search_term)
                      }}
                    >
                      <CustomText style={{ marginHorizontal: 10 }}>
                        {rec?.search_term}
                      </CustomText>
                    </TouchableOpacity>
                  ))}
                </View>
              )}
              <CustomText title>Los más vistos</CustomText>
              {recentSch?.most_viewed?.map((rec, i) => (
                <TouchableOpacity
                  key={i}
                  onPress={() =>
                    this.props.navigation.navigate('HomeTab', {
                      screen: 'PostShowScreen',
                      params: { post: rec },
                    })
                  }
                  style={{ marginBottom: 10 }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    {rec?.main_image && (
                      <Image
                        source={{
                          uri: `${global.BACKEND_HOST}${rec?.main_image}`,
                        }}
                        style={{ ...styles.trendingPostImage }}
                        resizeMode="cover"
                        transition={true}
                        placeholderStyle={{ backgroundColor: 'white' }}
                        containerStyle={{ resizeMode: 'cover' }}
                      />
                    )}
                    <View style={{ maxWidth: '80%' }}>
                      <CustomText
                        title
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          marginBottom: 5,
                        }}
                      >
                        {rec?.entity}
                      </CustomText>
                      <Text
                        ellipsizeMode="tail"
                        numberOfLines={2}
                        style={{ ...styles.cardBody }}
                      >
                        {rec?.title}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              ))}
            </ScrollView>
          )}
          numColumns={2}
          renderItem={({ item: e }) => {
            return (
              <PostCard
                favorite={this.isFavorite(e)}
                onPressFavorite={() =>
                  this.props.actions.toggleFavorite(
                    this.props.auth.access_token,
                    e.id,
                    this.isFavorite(e) ? 'remove' : 'add'
                  )
                }
                onPress={() =>
                  this.props.navigation.navigate('HomeTab', {
                    screen: 'PostShowScreen',
                    params: { post: e },
                  })
                }
                image={e?.main_image}
                title={e.entity}
                body={e.title}
                containerStyle={{
                  width: '45%',
                  marginHorizontal: 10,
                }}
                key={e.id}
                discount={e?.discount}
                discountShow={e?.kind === 'discount'}
              />
            )
          }}
          contentContainerStyle={{ paddingBottom: 100 }}
          onMomentumScrollEnd={() => pagination()}
          ListFooterComponent={() =>
            loading && <ActivityIndicator size={'large'} />
          }
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: 'white',
    paddingTop: 20,
    flex: 1,
    paddingHorizontal: Platform.OS === 'web' ? (checkWidth ? 0 : '40%') : 0,
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 13,
  },
  searchHeadVw: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  inputTxtVw: {
    width: '80%',
    paddingHorizontal: 20,
    height: 50,
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#F1F1F1',
  },
  favour: {
    marginLeft: 5,
    width: '20%',
    borderRadius: 10,
    height: 50,
    justifyContent: 'center',
    backgroundColor: '#F1F1F1',
    alignItems: 'center',
  },
  trendingPostImage: {
    width: 60,
    height: 60,
    borderRadius: 5,
    marginRight: 5,
  },
  cardBody: {
    color: '#666666',
    fontSize: 12,
  },
})
