import React, { Component } from 'react'
import {
  View,
  Dimensions,
  StyleSheet,
  TextInput,
  Alert,
  Platform,
} from 'react-native'
import { Button, Image, Text } from 'react-native-elements'
import RoundedButton from '../../components/ui_elements/rounded_button'
import CustomText from '../../components/ui_elements/custom_text'
import _ from 'lodash'
import B from '../../components/ui_elements/text_styling'
import { checkWidth } from '../../constants'

export default class LoginConfirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loginCode: {},
    }
  }

  componentDidMount() {
    if (this.props.auth?.user?.email) {
      this.setState({ email: this.props.auth?.user?.email })
    }
  }

  _login = () => {
    this.props.navigation.navigate('Login')
  }

  _renderAuthError = () => {
    if (this.props.auth.errors && Array.isArray(this.props.auth.errors)) {
      return (
        <View
          style={{
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            alignSelf: 'center',
          }}
        >
          <Text style={styles.inputErrorMsg} key="autherror">
            Código inválido
          </Text>
        </View>
      )
    }
  }

  _handleCodeInput = (pos, val) => {
    this.setState((prevState) => ({
      loginCode: { ...prevState.loginCode, [pos]: val.toUpperCase() },
    }))
    if (val && pos < 6) {
      if (pos == 2) {
        this[`codeInput${pos + 2}`].focus()
      } else {
        this[`codeInput${pos + 1}`].focus()
      }
    }
  }

  _renderCodeDigitFields = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          marginTop: 30,
          alignItems: 'center',
          width: Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
          alignSelf: 'center',
        }}
      >
        {_.times(7, (i) => {
          return (
            <View
              key={i}
              style={{
                width: `${100 / 7}%`,
                alignItems: 'center',
                paddingHorizontal: 2,
              }}
            >
              {i == 3 ? (
                <Text style={{ color: '#7962E0', fontSize: 30 }}>-</Text>
              ) : (
                <TextInput
                  type="text"
                  style={{
                    borderWidth: 1,
                    borderColor: '#7962E0',
                    borderRadius: 50,
                    height: 40,
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 24,
                    color: '#7962E0',
                  }}
                  autoCorrect={false}
                  placeholder="*"
                  autoCapitalize="none"
                  maxLength={1}
                  ref={(input) => {
                    this[`codeInput${i}`] = input
                  }}
                  onChangeText={(val) => this._handleCodeInput(i, val)}
                  value={this.state.loginCode[i]}
                />
              )}
            </View>
          )
        })}
      </View>
    )
  }

  _loginCode = () => {
    return _.reduce(
      this.state.loginCode,
      (res, val, k) => {
        return `${res}${val}`
      },
      '',
    )
  }

  _validateLoginCode = () => {
    if (this._loginCode().length < 6) {
      Alert.alert(
        'Error',
        'Debes ingresar los 6 digitos del código que te enviamos para continuar.',
        [{ text: 'OK' }],
      )
    } else {
      this.props.actions.login(this.state.email, this._loginCode())
    }
  }

  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={{ alignItems: 'center' }}>
          <CustomText title center>
            Revisa tu correo
          </CustomText>
          <CustomText center>
            Te enviamos un código a <B>{this.props.auth?.user?.email}</B>,
            ingresalo aquí para iniciar sesión.
          </CustomText>
        </View>

        <View>{this._renderCodeDigitFields()}</View>
        {this._renderAuthError()}

        <View
          style={{
            alignItems: 'center',

            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
            alignSelf: 'center',
          }}
        >
          <RoundedButton
            style={{ marginTop: 50, marginBottom: 20 }}
            onPress={() => this._validateLoginCode()}
            loading={this.props.auth.status == 'loading'}
          >
            Validar
          </RoundedButton>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    height: Dimensions.get('window').height,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: '#7962E0',
    borderRadius: 50,
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginTop: 15,
  },
  inputField: {
    fontSize: 16,
  },
  inputErrorMsg: {
    marginTop: 20,
    textAlign: 'center',
    color: 'red',
  },
})
