import { StyleSheet, Dimensions } from 'react-native'

export default StyleSheet.create({
  mainContainer: {
    flex: 1,
    height: Dimensions.get('window').height,
    backgroundColor: 'white',
  },
  headerTxt: { paddingTop: 20 },
  confirmVw: { padding: 20, alignSelf: 'flex-end' },
})
