import { fetchWithTimeout } from '../components/fetchWithTimeout'

export function addUserEvent(token, eventParams) {
  return async (dispatch) => {
    try {
      let response = await fetchWithTimeout(
        `${global.API_URL}/user_events`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({ ...eventParams }),
        },
        1500
      )
    } catch (error) {
      console.log(error)
    }
  }
}