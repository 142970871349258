import { StyleSheet, Dimensions, Platform } from 'react-native'
import { checkWidth } from '../../constants'
const width = Dimensions.get('window').width
const top = Dimensions.get('window').height * 0.1
const mapHeight = Dimensions.get('window').height * 0.5
export default StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection:
      Platform.OS === 'web' ? (checkWidth ? 'column' : 'row') : 'column',
  },
  headerTxt: { paddingTop: 20 },
  placePicker: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 30,
  },
  mapContainer: {
    top: '20%',
    position: 'absolute',
    zIndex: -999,
  },
  map: {
    width:
      Platform.OS === 'web'
        ? checkWidth
          ? Dimensions.get('window').width
          : Dimensions.get('window').width / 3
        : Dimensions.get('window').width,
    height: mapHeight,
    marginTop: Platform.OS === 'web' ? '10%' : 0,
  },
  confirmContainer: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 30,
    top: mapHeight + 50,
    zIndex: 99999999,
  },
  mobileVw: {
    width: Platform.OS === 'web' ? (checkWidth ? '100%' : '30%') : '100%',
  },
})
