import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Location from '../screens/Location'
import * as authActions from '../actions/authActions'

const actions = {
  ...authActions,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Location)
