import React, { Component } from 'react'
import {
  ActivityIndicator,
  View,
  ScrollView,
  Dimensions,
  StyleSheet,
  RefreshControl,
  Platform,
} from 'react-native'
import { Button, Image, Text } from 'react-native-elements'
import CustomText from '../components/ui_elements/custom_text'
import SquareButton from '../components/ui_elements/square_button'
import B from '../components/ui_elements/text_styling'
import PostCard from '../components/ui_elements/post_card'
import Banner from '../components/ui_elements/banner'
import LoginRequired from '../components/ui_elements/loginRequired'
import _ from 'lodash'
import { checkWidth } from '../constants'

export default class Favorites extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.auth.user.role !== 'guest')
      this.props.actions.getFavorites(this.props.auth.access_token)
  }

  fetchData

  _renderPosts() {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {_.map(this.props.posts?.favorites, (e) => {
          return (
            <PostCard
              favorite={true}
              onPressFavorite={() =>
                this.props.actions.toggleFavorite(
                  this.props.auth.access_token,
                  e.id,
                  'remove'
                )
              }
              onPress={() =>
                this.props.navigation.navigate('HomeTab', {
                  screen: 'PostShowScreen',
                  params: { post: e },
                })
              }
              // image={`${global.BACKEND_HOST}${e.main_image}`}
              image={e?.main_image}
              title={e.entity}
              body={e.title}
              key={e?.id}
              // containerStyle={{ width: '45%', marginHorizontal: 5 }}

              containerStyle={
                Platform.OS === 'web'
                  ? checkWidth
                    ? {
                        width: '45%',
                        marginHorizontal: 10,
                      }
                    : {}
                  : {
                      width: '45%',
                      marginHorizontal: 5,
                    }
              }
              discount={e?.discount}
              discountShow={e?.kind === 'discount'}
            />
          )
        })}
      </View>
    )
  }

  _onRefresh() {
    if (this.props.auth.user.role !== 'guest')
      this.props.actions.getFavorites(this.props.auth.access_token)
  }

  render() {
    return (
      <ScrollView
        style={styles.mainContainer}
        refreshControl={
          <RefreshControl
            refreshing={this.props.posts?.status == 'loading'}
            onRefresh={this._onRefresh.bind(this)}
          />
        }
        contentContainerStyle={{
          width: Platform.OS === 'web' ? (checkWidth ? '100%' : '50%') : '100%',
          alignSelf: 'center',
        }}
      >
        <View style={{ paddingBottom: 80 }}>
          {this.props.auth.user.role === 'guest' ? (
            <LoginRequired {...this.props} />
          ) : (
            <>
              <CustomText title center>
                Mis Favoritos
              </CustomText>
              <View style={{ alignItems: 'center', width: '100%' }}>
                <CustomText center style={{ maxWidth: 300 }}>
                  Productos y servicios favoritos {'\n'} escogidos por tí
                </CustomText>
              </View>
              {this._renderPosts()}
            </>
          )}
        </View>
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: 'white',
    paddingTop: 20,
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 13,
  },
})
