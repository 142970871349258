import React from 'react'
import { View, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'
import { Button, Image, Text } from 'react-native-elements'
import CustomText from './custom_text'
import Icon from 'react-native-vector-icons/FontAwesome5'

const Requirement = props => {

    return (
        <View style={{...styles.reqContainer}}>
            <CustomText style={{color: 'black'}}>
                <Icon name={props?.icon || 'circle-notch'} size={14} style={{...styles.reqIcon}} solid />  {props.text}
            </CustomText>
        </View>
    )
}

const styles = StyleSheet.create({
    reqContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    reqIcon: {
        color: '#7962E0',
    },
});

export default Requirement