import React from 'react'
import { useSelector } from 'react-redux'
import { NavigationContainer } from '@react-navigation/native'
import AuthStack from './authStack'
import MainTabnav from './mainTabnav'
import ProfileStack from './profileStack'

const AppNavigator = (props) => {
  const isAuth = useSelector(
    (state) => !!state.auth.access_token && !!state.auth.user,
  )
  const shouldCompleteProfile = useSelector(
    (state) => !!state.auth?.user?.should_complete_profile,
  )

  // const Stack = createNativeStackNavigator()
  const currentNavigator = useSelector((state) => state.auth.current_navigator)
  return (
    <NavigationContainer>
      {!isAuth && <AuthStack />}
      {isAuth && shouldCompleteProfile && <ProfileStack />}
      {isAuth && !shouldCompleteProfile && <MainTabnav />}
    </NavigationContainer>
  )
}

export default AppNavigator
