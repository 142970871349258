import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RequestLogin from '../screens/login/requestLogin';
import * as authActions from '../actions/authActions';

const actions = {
    ...authActions,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RequestLogin);