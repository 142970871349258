import { View, Text, Image, TouchableWithoutFeedback } from 'react-native'
import Icon from 'react-native-vector-icons/Feather'

export default function CategoryCard({ item, toggle, selectedItems }) {
  return (
    <TouchableWithoutFeedback onPress={() => toggle(item)}>
      <View
        style={{
          margin: 10,
          alignItems: 'center',
          width: 100,
        }}
      >
        <View
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            marginBottom: 10,
          }}
        >
          {item?.main_image && (
            <Image
              source={{ uri: global.BACKEND_HOST + item?.main_image }}
              style={{ width: 80, height: 80 }}
            />
          )}

          {selectedItems[item?.id] && (
            <View
              style={{
                width: 80,
                height: 80,
                backgroundColor: '#7962E0',
                opacity: 0.4,
                position: 'absolute',
              }}
            />
          )}
          {selectedItems[item?.id] && (
            <View
              style={{
                width: 80,
                height: 80,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Image
                source={require('../../../assets/iso-white.png')}
                style={{ width: 80, height: 80 }}
              />
            </View>
          )}
        </View>

        <View style={{ width: '80%', alignItems: 'center' }}>
          <Text numberOfLines={2} style={{ textAlign: 'center' }}>
            {item?.name}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}
