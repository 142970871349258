import React from 'react'
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { Image, Text } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome5'
import Entypo from 'react-native-vector-icons/Entypo'

const PostCard = (props) => {
  const handleLikePress = () => {
    // postsActions.toggleFavorite(this.props.true)
  }
  return (
    <TouchableOpacity
      style={{ ...styles.card, ...styles.shadowProp, ...props.containerStyle }}
      key={props.title}
      onPress={props.onPress}
    >
      {props.image ? (
        <Image
          source={{ uri: `${global.BACKEND_HOST}${props.image}` }}
          style={{ ...styles.cardImage }}
          resizeMode="cover"
          transition={false}
          placeholderStyle={{ backgroundColor: 'white' }}
          containerStyle={{ resizeMode: 'cover' }}
        />
      ) : (
        <View style={{ ...styles.cardImage, alignItems: 'center' }}>
          <Entypo name="images" size={120} />
        </View>
      )}

      {props?.discountShow && (
        <View
          style={{
            top: 0.2,
            right: 20,
            position: 'absolute',
            backgroundColor: '#7962E0',
            padding: 5,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        >
          {props?.discount == null ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Icon
                name="dollar-sign"
                style={{ color: 'white', fontSize: 12 }}
              />
              <Icon
                name="long-arrow-alt-down"
                style={{ color: 'white', fontSize: 12 }}
              />
            </View>
          ) : (
            <Text style={{ color: 'white', fontSize: 12 }}>
              {props?.discount + '%'}
            </Text>
          )}
        </View>
      )}

      <View style={{ ...styles.cardContent }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            ellipsizeMode="tail"
            numberOfLines={2}
            style={{ ...styles.cardTitle }}
          >
            {props.title}
          </Text>
          <TouchableOpacity
            onPress={props.onPressFavorite}
            style={{ paddingLeft: 10 }}
          >
            {props.favorite ? (
              <Icon name="star" size={16} style={{ color: '#fbc02d' }} solid />
            ) : (
              <Icon name="star" size={16} style={{ color: '#7962E0' }} />
            )}
          </TouchableOpacity>
        </View>
        <Text
          ellipsizeMode="tail"
          numberOfLines={2}
          style={{ ...styles.cardBody }}
        >
          {props.body}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    borderRadius: 5,
    width: 280,
    marginVertical: 10,
    marginHorizontal: 10,
    // overflow: 'hidden',
  },
  cardImage: {
    width: '100%',
    resizeMode: 'cover',
    height: 90,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  cardContent: {
    paddingHorizontal: 7,
    paddingTop: 10,
    paddingBottom: 10,
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#7962E0',
    maxWidth: '80%',
  },
  cardBody: {
    color: '#666666',
    fontSize: 12,
  },
  shadowProp: {
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,

    elevation: 5,
    shadowColor: '#111111',
  },
})

export default PostCard
