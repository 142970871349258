// import * as Sentry from '@sentry/react-native'
import { StyleSheet, Text, View, Platform } from 'react-native'
import React from 'react'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { compose, applyMiddleware, combineReducers, createStore } from 'redux'
import reducers from './app/reducers'
import Constants from 'expo-constants'

import AppNavigator from './app/components/nav/appNavigator'
import { MenuProvider } from 'react-native-popup-menu'
import { LogBox, Dimensions } from 'react-native'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({ ...reducers })
const persReducer = persistReducer(persistConfig, rootReducer)
// const store = createStore(persReducer, applyMiddleware(thunk))

const store = createStore(persReducer, composeEnhancers(applyMiddleware(thunk)))
const persistor = persistStore(store)

function App() {
  LogBox.ignoreLogs(['Warning: ...']) // Ignore log notification by message
  LogBox.ignoreAllLogs() //Ignore all log notifications

  let env = Constants.manifest.releaseChannel
  if (Constants.manifest.build_env) env = Constants.manifest.build_env
  if (__DEV__) {
    var conf = Constants.manifest.extra.development
  } else if (env === 'staging') {
    var conf = Constants.manifest.extra.staging
  } else if (env === 'production') {
    var conf = Constants.manifest.extra.production
  } else if (__DEV__ === false) {
    var conf = Constants.manifest.extra.production
  }

  global.BACKEND_HOST = conf.BACKEND_HOST
  global.API_URL = conf.API_URL

  return (
    <MenuProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppNavigator />
        </PersistGate>
      </Provider>
    </MenuProvider>
  )
}

// Sentry.init({
//   dsn: 'https://7ecbbd8cf26145df98f879ef6621586c@o4504749081034752.ingest.sentry.io/4504749082804224',
//   // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//   // We recommend adjusting this value in production.
//   tracesSampleRate: 1.0,
//   enableNative: false,
// })

// export default Sentry.wrap(App)
export default App

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
