import * as types from '../actionTypes/auth'
import { fetchWithTimeout } from '../components/fetchWithTimeout'

export function setStatusSuccess() {
  return async (dispatch) => {
    dispatch({ type: types.SET_STATUS_SUCCESS })
  }
}

export function setCurrentNavigator(nav) {
  return async (dispatch) => {
    dispatch({ type: types.SET_CURRENT_NAVIGATOR, data: nav })
  }
}

export function guestLogin() {
  return async (dispatch) => {
    dispatch({ type: types.GUEST_LOGIN })
    dispatch({ type: types.GUEST_LOGIN_SUCCESS })
  }
}

export function signup(userParams) {
  return async (dispatch) => {
    dispatch({ type: types.SIGNUP })
    try {
      let response = await fetchWithTimeout(
        `${global.API_URL}/users/signup`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: { ...userParams } }),
        },
        15000
      )
      if (response.status === 200) {
        dispatch({
          type: types.SIGNUP_SUCCESS,
          data: { email: userParams.email },
        })
      } else if (response.status === 300) {
        const resp = await response.json()
        dispatch({
          type: types.LOGIN_PENDING,
          data: { email: userParams.email },
        })
      } else if (response.status === 422) {
        const resp = await response.json()
        dispatch({ type: types.SIGNUP_ERROR, data: resp })
      } else {
        dispatch({
          type: types.SIGNUP_ERROR,
          data: {
            errors: {
              'Connection error': [`Response code ${response.status}`],
            },
          },
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: types.SIGNUP_ERROR,
        data: {
          errors: {
            'Connection error': [`Se ha excedido el tiempo de espera máximo`],
          },
        },
      })
    }
  }
}

export function requestLogin(userParams) {
  return async (dispatch) => {
    dispatch({ type: types.REQUEST_LOGIN, data: { email: userParams.email } })
    try {
      let response = await fetchWithTimeout(
        `${global.API_URL}/users/request_login_code`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: { ...userParams } }),
        }
      )
      console.log(response.status)
      if (response.status === 200) {
        response = await response.json()
        dispatch({ type: types.REQUEST_LOGIN_SUCCESS, data: response })
      } else if (response.status === 404) {
        response = await response.json()
        dispatch({ type: types.REQUEST_LOGIN_AUTH_ERROR, data: response })
      } else {
        dispatch({
          type: types.REQUEST_LOGIN_AUTH_ERROR,
          data: { error: `Response code ${response.status}` },
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: types.REQUEST_LOGIN_AUTH_ERROR,
        data: { error: `Connection failure` },
      })
    }
  }
}

export function login(email, loginCode) {
  return async (dispatch) => {
    dispatch({ type: types.LOGIN })
    try {
      let response = await fetchWithTimeout(`${global.API_URL}/users/sign_in`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: { email: email, password: loginCode } }),
      })
      if (response.status === 201) {
        const authToken = response.headers.get('Authorization')
        const resp = await response.json()
        dispatch({
          type: types.LOGIN_SUCCESS,
          data: {
            ...resp,
            authToken,
          },
        })
      } else if (response.status === 401) {
        response = await response.json()
        dispatch({ type: types.LOGIN_AUTH_ERROR, data: response })
      } else {
        dispatch({
          type: types.LOGIN_AUTH_ERROR,
          data: { error: `Response code ${response.status}` },
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: types.LOGIN_AUTH_ERROR,
        data: { error: `Connection failure` },
      })
    }
  }
}

export function getProfileOptions(token) {
  return async (dispatch) => {
    dispatch({ type: types.GET_PROFILE_OPTIONS })
    try {
      let response = await fetchWithTimeout(
        `${global.API_URL}/users/profile_options`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      )
      if (response.status === 200) {
        const resp = await response.json()
        dispatch({ type: types.GET_PROFILE_OPTIONS_SUCCESS, data: resp })
      } else if (response.status === 403) {
        dispatch({
          type: types.GET_PROFILE_OPTIONS_ERROR,
          data: { errors: { Forbidden: ["You don't have permissions"] } },
        })
      } else {
        dispatch({
          type: types.GET_PROFILE_OPTIONS_ERROR,
          data: {
            errors: {
              'Connection error': [`Response code ${response.status}`],
            },
          },
        })
      }
    } catch (error) {
      dispatch({
        type: types.GET_PROFILE_OPTIONS_ERROR,
        data: { errors: { 'Connection error': [`Unknown`] } },
      })
    }
  }
}

export function updateProfile(token, params) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE })
    try {
      let response = await fetchWithTimeout(`${global.API_URL}/users/profile`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ user: { ...params } }),
      })
      if (response.status === 200) {
        const resp = await response.json()
        if (resp.user == null) {
          console.log('ESTA VACIO')
        }
        dispatch({ type: types.UPDATE_PROFILE_SUCCESS, data: resp.user })
      } else if (response.status === 403) {
        dispatch({
          type: types.UPDATE_PROFILE_ERROR,
          data: { errors: { Forbidden: ["You don't have permissions"] } },
        })
        dispatch({ type: types.SIGNOUT_SUCCESS })
      } else if (response.status === 422) {
        const resp = await response.json()
        dispatch({ type: types.UPDATE_PROFILE_ERROR, data: resp })
      } else {
        dispatch({
          type: types.UPDATE_PROFILE_ERROR,
          data: {
            errors: {
              'Connection error': [`Response code ${response.status}`],
            },
          },
        })
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROFILE_ERROR,
        data: { errors: { 'Connection error': ['Unknown'] } },
      })
    }
  }
}

export function signout() {
  return async (dispatch) => {
    dispatch({ type: types.SIGNOUT })
    dispatch({ type: types.SIGNOUT_SUCCESS })
  }
}
