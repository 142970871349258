import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import React, { Component } from 'react'
import { View, Dimensions, StyleSheet } from 'react-native'
import CustomText from '../../components/ui_elements/custom_text'
import RoundedButton from '../../components/ui_elements/rounded_button'

import * as authActions from '../../actions/authActions'

class LoginOptions extends Component {
  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={{ alignItems: 'center' }}>
          <CustomText title center>
            ¡Bienvenid@!
          </CustomText>
          <CustomText center>
            Para acceder a los beneficios que tenemos para tí debes tener una
            cuenta.
          </CustomText>
          <CustomText center>Si ya tienes una cuenta</CustomText>
          <RoundedButton
            style={{
              backgroundColor: '#E2501E',
              marginTop: 10,
              marginBottom: 20,
            }}
            onPress={() => this.props.navigation.navigate('RequestLogin')}
          >
            Iniciar sesión
          </RoundedButton>
          <CustomText center>o puedes</CustomText>
          <RoundedButton
            style={{ marginTop: 10, marginBottom: 20, textAlign: 'center' }}
            onPress={() => this.props.actions.guestLogin()}
          >
            Ingresar como invitado
          </RoundedButton>
        </View>
      </View>
    )
  }
}

const actions = {
  ...authActions,
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginOptions)

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    height: Dimensions.get('window').height,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
  },
})
