import React, { Component } from 'react'
import {
  ActivityIndicator,
  View,
  FlatList,
  Dimensions,
  StyleSheet,
  Platform,
} from 'react-native'
import CustomText from '../components/ui_elements/custom_text'
import PostCard from '../components/ui_elements/post_card'
import _ from 'lodash'
import { getCategoriesPost } from '../actions/postsActions'
const height = Dimensions.get('window').height
import { checkWidth } from '../constants'
export default class CategoriesSearch extends Component {
  constructor(props) {
    super(props)
    this.state = { records: [], loading: true }
  }
  componentDidMount() {
    if (_.isObject(this.props.route?.params)) {
      var raw = {
        q: {
          categories_id_eq: this.props.route?.params?.id,
        },
      }

      getCategoriesPost(this.props.auth.access_token, raw).then((res) =>
        this.setState({ records: res, loading: false }),
      )
    } else {
      var raw = {
        q: {
          public_private_eq: this.props.route?.params,
        },
      }
      getCategoriesPost(this.props.auth.access_token, raw).then((res) =>
        this.setState({ records: res, loading: false }),
      )
    }
  }

  isFavorite(p) {
    if (this.props.posts?.favorites) {
      return _.includes(_.map(this.props.posts.favorites, 'id'), p.id)
    } else {
      return false
    }
  }

  render() {
    const params = this.props.route?.params
    return (
      <View style={styles.mainContainer}>
        <FlatList
          data={this.state.records}
          ListHeaderComponent={() => (
            <CustomText title center style={{ paddingTop: 10 }}>
              {_.isObject(params)
                ? params?.name
                : params === 'private'
                ? 'Privados'
                : 'Públicos'}
            </CustomText>
          )}
          numColumns={Platform.OS === 'web' ? (checkWidth ? 2 : 3) : 2}
          renderItem={({ item: e }) => {
            return (
              <PostCard
                favorite={this.isFavorite(e)}
                onPressFavorite={() =>
                  this.props.actions.toggleFavorite(
                    this.props.auth.access_token,
                    e.id,
                    this.isFavorite(e) ? 'remove' : 'add',
                  )
                }
                onPress={() =>
                  this.props.navigation.navigate('HomeTab', {
                    screen: 'PostShowScreen',
                    params: { post: e },
                  })
                }
                image={e?.main_image}
                title={e.entity}
                body={e.title}
                // containerStyle={}
                containerStyle={
                  Platform.OS === 'web'
                    ? checkWidth
                      ? {
                          width: '45%',
                          marginHorizontal: 10,
                        }
                      : {}
                    : {
                        width: '45%',
                        marginHorizontal: 10,
                      }
                }
                key={e.id}
                discount={e?.discount}
                discountShow={e?.kind === 'discount'}
              />
            )
          }}
          contentContainerStyle={{
            width:
              Platform.OS === 'web' ? (checkWidth ? '100%' : '70%') : '100%',
            alignSelf: 'center',
            paddingBottom: 100,
            alignItems: 'center',
          }}
          ListEmptyComponent={() =>
            this.state.loading && (
              <View style={styles.loadingVw}>
                <ActivityIndicator size={'large'} />
              </View>
            )
          }
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: 'white',
    flex: 1,
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 13,
  },
  searchHeadVw: { flexDirection: 'row', alignItems: 'center', padding: 20 },
  inputTxtVw: {
    width: '80%',
    padding: 20,
    borderRadius: 10,
    backgroundColor: '#F1F1F1',
  },
  favour: {
    marginLeft: 5,
    width: '20%',
    padding: 12,
    borderRadius: 10,
    backgroundColor: '#F1F1F1',
    alignItems: 'center',
  },
  loadingVw: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: height - 200,
  },
})
