import React from 'react'
import { Text, StyleSheet } from 'react-native'

const CustomText = (props) => {
  return (
    <Text
      style={[
        props.title ? { ...styles.titleStyling, ...props.textStyle } : { ...styles.textStyling, ...props.textStyle },
        props.center ? { textAlign: 'center' } : {},
        { ...props.style },
        props.marginBottom && { marginBottom: 0 },
      ]}
      onPress={props.onPress}
    >
      {props.children}
    </Text>
  )
}

const styles = StyleSheet.create({
  titleStyling: {
    color: '#7962E0',
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 15,
  },
  textStyling: {
    color: '#676767',
    fontSize: 16,
    marginBottom: 15,
  },
})

export default CustomText
