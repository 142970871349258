const INITIAL_STATE = {
    status: null,
    records: [],
    errors: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_CATEGORIES':
            return {
                ...state,
                status: 'loading',
                errors: null,
            }
        case 'GET_CATEGORIES_SUCCESS':
            return {
                ...state,
                status: 'success',
                records: action.data,
                errors: null,
            }
        case 'GET_CATEGORIES_ERROR':
            return {
                ...state,
                status: 'failure',
                errors: action.data.errors,
            }
        default:
            return state;
    }
};